import { useContext, useRef, useState } from 'react';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Appbar from "./components/AppBar/AppBar";
import Banner from "./components/Banner/Banner";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "./components/CategoriesCarousel/CategoriesCarousel";
import BottomTabs from "../../components/BottomTabs/BottomTabs.js";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CategoriesSkeleton from '../../components/skeleton/CategoriesSkeleton/CategoriesSkeleton';
import BannerSkeleton from '../../components/skeleton/BannerSkeleton/BannerSkeleton';
import ProductsListSkeleton from '../../components/skeleton/ProductsListSkeleton/ProductsListSkeleton';
import useCategories from '../../hooks/useCategories';
import useOffers from '../../hooks/useOffers';
import { ThemeOptionsContext } from '../../theme/ThemeProvider';
import ProductsCarousel from './components/ProductsCarousel/ProductsCarousel';
import CategoriesGrid from './components/CategoriesGrid/CategoriesGrid';
import ProductsFullWidthSkeleton from '../../components/skeleton/ProductsFullWidthSkeleton/ProductsFullWidthSkeleton';
import ProductsWideList from './components/ProductsWideList/ProductsWideList';
import useBanners from '../../hooks/useBanners.js';


const HomePage = () => {
  const wrapperRef = useRef();
  const navigate = useNavigate();
  const categoriesQuery = useCategories();
  const bannersQuery = useBanners();
  const offersQuery = useOffers();
  const appearanceCtx = useContext(ThemeOptionsContext);

  return (
    <>
      <Box sx={{ mb: '70px' }} className="HomePage" ref={wrapperRef}>
        <Appbar title="Welcome to elsaidy" enableGps={true} />
        {/* <SectionTitle
          title="Welcome, Mohamed"
        /> */}
        {!bannersQuery.isLoading && <Banner items={bannersQuery.data?.banners} />}
        {bannersQuery.isLoading && <BannerSkeleton />}



        {(categoriesQuery.data?.categories?.length > 0 && !categoriesQuery.isLoading) && <SectionTitle
          title="Categories"
          actionLabel="See All"
          onclick={() => {
            navigate(`/categories${offersQuery.data?.items.length > 0 ? '/offers' : ''}`);
          }}
        />}

        {(!categoriesQuery.isLoading && appearanceCtx.appearance.category_view === 'carousel') && <CategoriesCarousel items={categoriesQuery.data?.categories} />}
        {(!categoriesQuery.isLoading && appearanceCtx.appearance.category_view === 'grid') && <CategoriesGrid items={categoriesQuery.data?.categories} />}



        {categoriesQuery.isLoading && <>
          <SectionTitle
            title="Categories"
            actionLabel="See All"
          />
          <CategoriesSkeleton /></>}
          <Box sx={{height:'16px'}} />

        {(!offersQuery.isLoading && offersQuery.data?.items.length > 0) && <>
          <SectionTitle
            title="Recommend for you"
            actionLabel="See All"
            onclick={() => {
              navigate("/categories/offers");
            }}
          />
          {appearanceCtx.appearance.recommend_style === 'full_width' &&
            <ProductsCarousel items={offersQuery.data?.items} />
          }

          {appearanceCtx.appearance.recommend_style !== 'full_width' &&
            <ProductsWideList items={offersQuery.data?.items} />
          }

        </>}
        {offersQuery.isLoading && <>
          <SectionTitle
            title="Recommend for you"
            actionLabel="See All"

          />
          {appearanceCtx.appearance.recommend_style !== 'full_width' && <ProductsListSkeleton length={2} />}
          {appearanceCtx.appearance.recommend_style === 'full_width' && <ProductsFullWidthSkeleton length={2} />}

        </>}
      </Box>
      <BottomTabs />
    </>
  );
};

export default HomePage;
