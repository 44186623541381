import { Box } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import DeliverySelector from "./components/DeliverySelector/DeliverySelector";
import { useState, useContext } from "react";
import DeliveryOption from "./components/DeliveryOption/DeliveryOption";
import PickUpOption from "./components/PickUpOption/PickUpOption";
import CheckoutContext from "../../store/checkout-context";
import InfoContext from "../../store/info-context";

const DeliveryOptionsPage = () => {
    const { deliveryType } = useContext(CheckoutContext);


    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState((deliveryType === 'delivery') || (deliveryType === 'pickup') ? deliveryType : 'delivery');
    const { info } = useContext(InfoContext);
    const onDeliveryOptionChange = (value) => {
        setSelectedDeliveryOption(value);
    }

    return (<>
        <Box id="DeliveryOptionsPage">
            <CustomAppBar hasBack enableGps disableGpsLink gpsTop backDark />
            <Box className="p-16">
        
                {info?.delivery_pickup === 'all' && <DeliverySelector selected={selectedDeliveryOption} onchange={onDeliveryOptionChange} />}
                {info?.delivery_pickup === 'all' && <>
                    {selectedDeliveryOption === 'delivery' && <DeliveryOption />}
                    {selectedDeliveryOption === 'pickup' && <PickUpOption />}
                </>}

                {info?.delivery_pickup === 'delivery' && <DeliveryOption />}
                {info?.delivery_pickup === 'pickup' && <PickUpOption />}

            </Box>
        </Box>
    </>);
};

export default DeliveryOptionsPage;