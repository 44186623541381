import { Grid, ButtonBase, Typography, Box, Paper, useTheme, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import Styles from "./ProductItem.module.css";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import { excerpt } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ThemeOptionsContext } from "../../theme/ThemeProvider";
import InfoContext from "../../store/info-context";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductItem = ({ item, }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [basePrice, setBasePrice] = useState();
  const [imageSrc, setImageSrc] = useState();
  const theme = useTheme();
  const infoCtx = useContext(InfoContext);

  const themOptionsCtx = useContext(ThemeOptionsContext);

  useEffect(() => {
    if (item?.type === 'simple') {
      setBasePrice(parseInt(item?.item_option[0]?.price));
    };

    if (item.image && item.image.length > 2) {

      if (item.image.includes('demo')) {
        setImageSrc(config.url + '/' + item.image);
      } else {
        setImageSrc(config.assets + '/' + item.image);
      };


    } else {
      setImageSrc(config.assets + infoCtx.info?.logo);
    }

  }, []);

  const simplePriceBase = (options) => {
    return parseInt(options[0]?.price);
  }

  const variablePriceBase = (options) => {
    let prices = options.map(item => parseInt(item?.price));
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    if((max || min) !== 0 ){
      return min + ' - ' + max;
    }else{
      return 0;
    }
    
  }


  return (

    <>
      {themOptionsCtx.appearance.menu_view !== 'without_img' && <Paper className={Styles.ProductItem} elevation={1}>
        <ButtonBase sx={{ position: 'relative' }} component="div" onClick={() => { navigate(`/products/${item.id}`) }} >
          <Grid container alignItems="center">

            <Grid item xs={8}>
              <Box className="p-16" style={{ height: '100%' }}>

                <Typography
                  variant="subtitle1"
                  component="h5"
                  fontSize={16}
                  fontWeight="bold"
                  color="#172327"
                >
                  {item.name}
                </Typography>
                {item.desc && <Typography
                  variant="body1"
                  component="p"
                  color="#172327"
                  fontSize={12}
                  fontWeight="normal"
                  className={Styles.ProductItem_description}
                >
                  {item.desc && excerpt(item.desc, 50)}
                </Typography>}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "16px",
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>



                    {item.type === 'variable' && <Typography
                      variant="body1"
                      component="p"
                      fontSize={14}
                      color="black"
                      sx={{ margin: 0 }}
                      lineHeight={1}
                    >
                      {t('Price on selection')}
                    </Typography>}


                    {item.type === 'simple' && <Typography
                      variant="body1"
                      component="p"
                      fontSize={14}
                      color="black"
                      sx={{ margin: 0 }}
                      lineHeight={1}
                    >
                      {simplePriceBase(item?.item_option) > 0 && <>
                        {simplePriceBase(item?.item_option)} {t('EGP')}
                      </>}
                      {simplePriceBase(item?.item_option) === 0 && <>
                        {t('Price on selection')}
                      </>}
                    </Typography>}
                  </Box>

                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>

              <LazyLoadImage effect='blur' height="125" width="125" src={imageSrc} className={`${Styles.ProductItem_image} ${!item.image ? Styles.ProductItem_no_image : undefined}`} alt='' />
            </Grid>
          </Grid>

        </ButtonBase>
      </Paper>}
      {themOptionsCtx.appearance.menu_view === 'without_img' && <Paper className={Styles.ProductItem} elevation={0}>
        <ButtonBase sx={{ position: 'relative', width: '100%' }} component="div" onClick={() => { navigate(`/products/${item.id}`) }} >
          <Grid container>
            <Grid item xs={12}>
              <Box className="p-16">

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="h5"
                    fontSize={16}
                    fontWeight="bold"
                    color="#172327"
                  >
                    {item.name}
                  </Typography>

                  {item.type === 'variable' && <Typography
                    variant="body1"
                    component="p"
                    fontSize={16}
                    color="black"
                    fontWeight="bold"
                    sx={{ margin: 0 }}
                    lineHeight={1}
                  >
                    { variablePriceBase(item?.item_option) !== 0 ? <>
                      {variablePriceBase(item?.item_option)} {t('EGP')}
                    </> : t('Price on selection')}
                   
                  </Typography>}
                  {item.type === 'simple' && <Typography
                    variant="body1"
                    component="p"
                    fontSize={16}
                    color="black"
                    fontWeight="bold"
                    sx={{ margin: 0 }}
                    lineHeight={1}
                  >
                        {simplePriceBase(item?.item_option) > 0 && <span style={{whiteSpace:'nowrap'}}>
                        {simplePriceBase(item?.item_option)} {t('EGP')}
                      </span>}
                      {simplePriceBase(item?.item_option) === 0 && <>
                        {t('Price on selection')}
                      </>}
                  </Typography>}
                </Box>
                {item.desc && <Typography
                  variant="body1"
                  component="p"
                  color="#172327"
                  fontSize={12}
                  sx={{}}
                  fontWeight="normal"
                >
                  {excerpt(item.desc, 50)}
                </Typography>}
              </Box>
            </Grid>
          </Grid>
        </ButtonBase>
        <Divider sx={{ width: '100%' }} />
      </Paper>}
    </>

  );
};
export default ProductItem;
