import Styles from '../ProductDetailsPage.module.css';
import { Alert, Paper, Box, Button } from "@mui/material";
import { useNavigate, Link } from 'react-router-dom';
import CheckoutContext from '../../../store/checkout-context';
import { useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import { useTranslation } from 'react-i18next';

const ProductFooter = ({ itemTotal, extrasMeta, qty, extrasTotalQty, onAddToCart, productId ,generalExtrasQty, generalExtrasMeta}) => {

    const navigate = useNavigate();
    const checkoutCtx = useContext(CheckoutContext);
    const authCtx = useContext(AuthContext);
    const { t, i18n } = useTranslation();

   const navigateToDeliveryOptions = ()=>{
    sessionStorage.removeItem('rdx_product');
    sessionStorage.setItem('rdx_product',productId);
    navigate('/delivery');
   };



    return (
        <Paper elevation={3} className={`${Styles.BottomTabs} ${i18n.resolvedLanguage === 'ar' && Styles.BottomTabs_rtl}`} sx={{ mt: "auto", padding: '10px', backgroundColor: '#fff' }}>

            {(checkoutCtx.zoneCovered === false && checkoutCtx.deliveryType === 'delivery') && <Alert onClick={() => { navigate('/delivery') }} severity="error" sx={{ mb: '10px', cursor: 'pointer' }} action={
                <Button onClick={navigateToDeliveryOptions} color="inherit" size="small">
                    {t('Change Location')}
                </Button>
            }>{t('Sorry, we do not cover your location!')}</Alert>}

            {!authCtx.branch?.id && <Alert onClick={() => { navigate('/delivery') }} severity="error" sx={{ mb: '10px', cursor: 'pointer' }} action={
                <Button onClick={navigateToDeliveryOptions} color="inherit" size="small" >
                    {t('Change Location')}
                </Button>
            }>{t('Please Pick your location')}</Alert>}

{/* {((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty)} */}


{/* {
    JSON.stringify(itemTotal)
} */}



            {extrasMeta?.maximum > 0 && <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} disabled={(checkoutCtx.zoneCovered === false && checkoutCtx.deliveryType === 'delivery') || (!authCtx.branch?.id) || (extrasMeta && ((extrasTotalQty > extrasMeta?.maximum) || (extrasTotalQty < extrasMeta?.minimum))) || (generalExtrasMeta && ((generalExtrasQty < generalExtrasMeta?.minimum) || (generalExtrasQty > generalExtrasMeta?.maximum))) || (((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) === 0)} variant="contained" size="large" fullWidth color="primary" onClick={onAddToCart} >{t('Add To Cart')} <Box sx={{ mx: '10px' }} /> {((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) > 0 &&
                <span>{(itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty} {t('EGP')}</span>}
                {((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) === 0 && <span>{t('Price on selection')}</span>}
            </Button>}


            {!extrasMeta?.maximum && <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} disabled={(checkoutCtx.zoneCovered === false && checkoutCtx.deliveryType === 'delivery') || (!authCtx.branch?.id) || ((generalExtrasQty > generalExtrasMeta?.maximum) || (generalExtrasQty < generalExtrasMeta?.minimum)) || (((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) === 0)} variant="contained" size="large" fullWidth color="primary" onClick={onAddToCart} >{t('Add To Cart')} <Box sx={{ mx: '10px' }} /> {((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) > 0 &&
              
              
              <span>  {(itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty} {t('EGP')}</span>}
                {((itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras) * qty) === 0 && <span>{t('Price on selection')}</span>}
            </Button>}


          
            

        </Paper>
    )
};

export default ProductFooter;