import { useTranslation } from 'react-i18next';
import './style.css';
import { useContext } from 'react';
import { ThemeOptionsContext } from '../../../theme/ThemeProvider';


const CategoriesSkeleton = () => {

    const skeletonData = Array.from(Array(12).keys());
    const themeCtx = useContext(ThemeOptionsContext);

    const { i18n } = useTranslation();

    return <div className='categories-skeleton'><div className={`categories-skeleton__wrapper ${themeCtx.appearance.category_view === 'grid' && 'categories-skeleton__wrapper--grid'}`} >
        {
            skeletonData.map((item, i) => {
                return (<div key={i} className='categories-skeleton__item '>
                      <span className='categories-skeleton__img skeleton'></span>
                    <div className='categories-skeleton__title skeleton'></div>
                </div>)
            })
        }

    </div></div>
}



export default CategoriesSkeleton;