import { Box, Button, Divider, Grid } from '@mui/material';
import CustomAppBar from '../../components/CustomAppBar/CustomAppBar';
import ItemsList from './components/ItemsList/ItemsList';
import OrderTotals from './components/OrderTotals/OrderTotals';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrderMeta from './components/OrderMeta/OrderMeta';
import useOrder from '../../hooks/useOrder';
import SuspenseLoader from '../../components/SuspenseLoader/SuspenseLoader';


const OrderDetailsPage = () => {
    const { id } = useParams();
    const { i18n, t } = useTranslation();
    const orderQuery = useOrder(id);
    return (<Box id="OrderDetailsPage" sx={{ minHeight: '100%' }}>
        <CustomAppBar hasBack backDark title={`${!orderQuery.isLoading ? orderQuery.data?.inv_num : ''}`} notif />
        {orderQuery.isLoading && <SuspenseLoader />}
        {!orderQuery.isLoading && <ItemsList items={orderQuery.data?.items} />}
        {!orderQuery.isLoading && <Box sx={{ p: '16px' }}>
            <OrderMeta order={orderQuery.data} />
            <Divider />
            <OrderTotals totals={orderQuery.data} />
            <Grid item  sx={{flex:1}}>
                {(orderQuery.data?.status === 'completed') && <Button variant="contained" disableElevation={true} fullWidth component={Link} to={`/review-order/${orderQuery.data?.id}`}>{t('Review')}</Button>}
                {(orderQuery.data?.status !== 'completed') && <Button variant="contained" disableElevation={true} fullWidth component={Link} to={`/track-order/${orderQuery.data?.id}`}>{t('Track Order')}</Button>}
            </Grid>
        </Box>}
    </Box>)
};

export default OrderDetailsPage;