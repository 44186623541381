
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, useTheme, Alert, Stack, ButtonBase, Box } from '@mui/material';
import { MenuIcon, GpsFixedSharp, NotificationsOutlined, FmdGood, KeyboardArrowDown } from '@mui/icons-material';
import { ThemeOptionsContext } from '../../../../theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import CheckoutContext from '../../../../store/checkout-context';
import InfoContext from '../../../../store/info-context';
import AuthContext from '../../../../store/auth-context';
import ClosedBanner from '../../../../components/ClosedBanner/ClosedBanner';

const Appbar = (props) => {
    const infoCtx = useContext(InfoContext);
    const checkoutCtx = useContext(CheckoutContext);
    const theme = useTheme();
    const themeOptionsCtx = useContext(ThemeOptionsContext);
    const authCtx = useContext(AuthContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLocationHandler = () => {
        navigate('/delivery');
    }

    return (
        <>

            <AppBar position="sticky" elevation={0} color="transparent" sx={{
                borderBottom:'1px solid #ddd',
                backgroundImage: themeOptionsCtx.appearance?.header_style === 'gradient' ? `linear-gradient(to bottom, ${themeOptionsCtx.appearance?.header_gradient_first_color} 5%, ${themeOptionsCtx.appearance?.header_gradient_second_color} 100%)` : 'none',
                backgroundColor: themeOptionsCtx.appearance?.header_style !== 'color' ? 'transparent' : themeOptionsCtx.appearance.header_bg_color, zIndex: 10000
            }}>
                <ClosedBanner />
                {(infoCtx.info?.account_active === true && props.enableGps) && <Toolbar style={{ justifyContent: 'space-between' }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', overflow: 'hidden' }} onClick={changeLocationHandler}>
                        <FmdGood style={{ fontSize: '16px', marginRight: i18n.resolvedLanguage === 'en' ? '10px' : '0', marginLeft: i18n.resolvedLanguage === 'en' ? '0' : '10px' }} htmlColor={!themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color} />
                        {checkoutCtx.deliveryType === 'delivery' && <Typography title={checkoutCtx.deliverAddress?.address} variant="h6" component="div" style={{ fontSize: '14px', fontWeight: 'normal', color: !themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color }} noWrap >
                            {t('Delivery')} : {checkoutCtx.deliverAddress?.address || t('Pick Location')}
                        </Typography>}
                        {(checkoutCtx.deliveryType === 'pickup' && authCtx.branch?.id) && <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontSize: '14px', fontWeight: 'normal', color: !themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color }}>
                            {t('Pick Up')}: {t('Branch')} {authCtx.branch?.name || authCtx.branch?.id}
                        </Typography>}
                        {(checkoutCtx.deliveryType === 'dinein' && checkoutCtx.dineInOption?.table && checkoutCtx.dineInOption?.section) && <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontSize: '14px', fontWeight: 'normal', color: !themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color }}>
                            {authCtx.branch?.name}, {t('Section')} {checkoutCtx.dineInOption?.section} , {t('Table')} {checkoutCtx.dineInOption?.table}
                        </Typography>}
                        {!checkoutCtx.deliveryType && <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontSize: '12px', fontWeight: 'normal', color: !themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color }}>

                            {infoCtx.info?.delivery_pickup === 'all' && <>
                                {t('Select your Location for delivery')} <br />
                                {t('or Branch for Pickup')}
                            </>}
                            {infoCtx.info?.delivery_pickup === 'delivery' && <>
                                {t('Select your Location for delivery')}
                            </>}
                            {infoCtx.info?.delivery_pickup === 'pickup' && <>
                                {t('Select your Branch for Pickup')}
                            </>}
                        </Typography>}
                        <KeyboardArrowDown sx={{ marginLeft: '5px', marginRight: '5px' }} htmlColor={themeOptionsCtx.appearance?.header_text_color} />
                    </Box>


                    <IconButton size="small" edge="end" color="inherit" aria-label="menu" component={Link} to="/notifications">
                        <NotificationsOutlined htmlColor={themeOptionsCtx.appearance?.header_text_color} />
                    </IconButton>
                </Toolbar>}
                {infoCtx.info?.account_active === false && <Toolbar style={{ justifyContent: 'space-between' }}>

                    <Typography fontWeight="bold" sx={{ color: !themeOptionsCtx.appearance?.header_bg_color ? theme.palette.primary.main : themeOptionsCtx.appearance.header_text_color }}>{infoCtx.info?.name}</Typography>

                    <IconButton size="small" edge="end" color="inherit" aria-label="menu" component={Link} to="/notifications">
                        <NotificationsOutlined htmlColor={themeOptionsCtx.appearance?.header_text_color} />
                    </IconButton>
                </Toolbar>}
            </AppBar>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {(checkoutCtx.zoneCovered === false && checkoutCtx.deliveryType === 'delivery') && <Alert severity="error">{t('Sorry, we do not cover your location!')}</Alert>}
                {/* { !authCtx.branch?.id && <Alert severity="error"  action={<ButtonBase component={Link} to="/delivery"><Typography textTransform="uppercase" fontSize="14px" sx={{p:'10px'}} color="#000">Change</Typography></ButtonBase>}>Sorry, we do not cover your location!</Alert>} */}

            </Stack>
        </>
    )
};

export default Appbar;