import { useQuery } from "@tanstack/react-query";
import { fetchInfo } from "../providers/info";

const useInfo = ()=>{

    const infoQuery = useQuery({
        queryKey:['info'],
        queryFn:fetchInfo
    });

    return infoQuery;

};

export default useInfo;