const PlusIcon = (props) => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 11.2143H10.7143V15.5H9.28571V11.2143H5V9.78571H9.28571V5.5H10.7143V9.78571H15V11.2143Z" fill={props.color} />
            <path d="M19.5 10.5V20H10C4.75329 20 0.5 15.7467 0.5 10.5C0.5 5.25329 4.75329 1 10 1C15.2467 1 19.5 5.25329 19.5 10.5Z" stroke={props.color} />
        </svg>


    )
};

export default PlusIcon;