import {
    Button, Box, DialogTitle, Dialog, DialogActions, FormControl,
    RadioGroup,
    FormControlLabel,
    Radio, Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const LanguageDialog = (props) => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);
    const { onClose, open, languages } = props;

    const handleClose = () => {
        onClose();
    };
    const languageSelectHandler = (language) => {
        setSelectedLanguage(language);

        //
    };

    const switchLanguageHandler = (e) => {

        i18n.changeLanguage(selectedLanguage).then(() => {
            onClose();
        });

    }

    return (
        <Dialog onClose={handleClose} maxWidth="lg" fullWidth={true} open={open}>
            <DialogTitle>{t('Select Language')}</DialogTitle>
            <Box sx={{ width: '100%',px:'20px' }} >
                <FormControl>
                    <RadioGroup
                        onChange={(e)=>setSelectedLanguage(e.target.value)}
                        defaultValue={i18n.resolvedLanguage}
                        name="radio-buttons-group"
                    >
                        {languages?.map((language, i) => {
                            return (<FormControlLabel key={i} value={language.language_code} control={<Radio />} label={language.language_name} />)
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
            <DialogActions>
                <Button onClick={() => onClose()}>{t('Cancel')}</Button>
                <Button onClick={() => switchLanguageHandler()}>{t('Switch')}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default LanguageDialog;