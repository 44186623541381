import { createTheme, ThemeProvider } from '@mui/material';
import { useState, useEffect, createContext } from 'react';
import axiosConfig from '../config/axios';
import { useTranslation } from 'react-i18next';


const fetchAppearance = async () => {
    try {
        const themeRes = await axiosConfig.get(`/appearance`);
        return { appearance: themeRes.data.appearance };
    } catch (error) {
        console.log(error);
    }
}

const ThemeContextProvider = (props) => {
    const { i18n } = useTranslation();
    const [theme, setTheme] = useState({
        primary_color: "#eb0000",
        secondary_color: "#ffffff"
    });

    useEffect(() => {
        fetchAppearance().then(theme => {
            setTheme(theme.appearance);
        });
    }, []);

    const themeCtx = createTheme({
        direction: i18n.resolvedLanguage === 'ar' ? 'rtl' : 'ltr',
        typography: {
            fontFamily: [
                i18n.resolvedLanguage === 'ar' ? ["Vazirmatn"] : 'Roboto'
            ].join(','),
        },
        palette: {
            primary: {
                main: theme?.primary_color || '#000'
            },
            secondary: {
                main: theme?.secondary_color || '#000'
            },
            light: {
                main: '#fff'
            },
            dark:{
                main:'#999'
            }
        },

    });

    return (
        <ThemeProvider theme={themeCtx}>
            {props.children}
        </ThemeProvider>
    );
};

export const ThemeOptionsContext = createContext({
    appearance: {
        category_view:null,
        menu_view:null,
        banner_style: null,
        enable_intro: null,
        font_family: null,
        font_size: null,
        header_bg_color: null,
        header_bg_image: null,
        intro_text_color: null,
        header_gradient_first_color: null,
        header_gradient_second_color: null,
        header_style: null,
        header_text_color: null,
        intro_bg_color: null,
        intro_bg_image: null,
        intro_style: null,
        link_color: null,
        menu_style: null,
        product_style: null,
        recommend_style: null,
    }
})

export const AppThemeOptionsProvider = (props) => {

    const [options, setOptions] = useState({
        category_view:'carousel',
        menu_view:null,
        banner_style: 'boxed',
        enable_intro: "1",
        font_family: "Actor",
        font_size: "14",
        header_bg_color: "#3700ff",
        header_bg_image: null,
        header_gradient_first_color: "#ffffff",
        header_gradient_second_color: "#ffffff",
        header_style: "color",
        header_text_color: "#000000",
        intro_bg_color: "#ffffff",
        intro_bg_image: null,
        intro_style: "color",
        intro_text_color: '#000',
        link_color: "#ffffff",
        menu_style: "icons",
        product_style: "circle_image",
        recommend_style: "full_width",
    });
    const [loadingAppearance, setLoadingApppearance] = useState(true);

    useEffect(() => {
        fetchAppearance().then(theme => {
            setOptions(theme.appearance);
            setTimeout(() => {
                setLoadingApppearance(false);
            }, 2000);
        }).catch(error=>{
            setLoadingApppearance(false);

        });
    }, []);




    const themOptionsCtx = {
        loadingAppearance: loadingAppearance,
        appearance: {
            category_view:options?.styles?.category_view,
            menu_view:options?.styles?.menu_view,
            banner_style: options.banner_style,
            enable_intro: options.enable_intro,
            font_family: options.font_family,
            font_size: options.font_size,
            header_bg_color: options.header_bg_color,
            header_bg_image: options.header_bg_image,
            header_gradient_first_color: options.header_gradient_first_color,
            header_gradient_second_color: options.header_gradient_second_color,
            header_style: options.header_style,
            header_text_color: options.header_text_color,
            intro_bg_color: options.intro_bg_color,
            intro_bg_image: options.intro_bg_image,
            intro_text_color: options.intro_text_color,
            intro_style: options.intro_style,
            link_color: options.link_color,
            menu_style: options.menu_style,
            product_style: options.product_style,
            recommend_style: options.recommend_style,
        }

    }

    return <ThemeOptionsContext.Provider value={themOptionsCtx} >{props.children}</ThemeOptionsContext.Provider>
}

export default ThemeContextProvider;