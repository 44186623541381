import { useQuery } from '@tanstack/react-query';
import { fetchProduct } from '../providers/product';
import { useTranslation } from 'react-i18next';

const useProduct = (id) => {
    const { i18n } = useTranslation();
    const productQuery = useQuery({
        queryKey: ['product', id],
        queryFn: () => fetchProduct(id, i18n)
    })
    return productQuery;
};

export default useProduct;