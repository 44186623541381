const IncreaseIcon = ({color}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10V20H10C4.47715 20 0 15.5228 0 10Z" fill={color}/>
        <path d="M15.5969 10.4551H10.4551V15.5969H8.74111V10.4551H3.59928V8.74111H8.74111V3.59928H10.4551V8.74111H15.5969V10.4551Z" fill="white"/>
        </svg>
        
    );
  };
  
  export default IncreaseIcon;
  