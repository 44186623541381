import { Button, Box, DialogTitle, Dialog, DialogActions, ButtonBase, Typography, DialogContent } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const LogoutDialog = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const authCtx = useContext(AuthContext);
    const { onClose, open } = props;
    const queryClient = useQueryClient();

    const handleClose = () => {
        onClose();
    };
  

    const logoutHandler = () => {
        authCtx.logout();
        queryClient.clear();
        navigate('/login');
        onClose();
    }

    return (
        <Dialog onClose={handleClose} maxWidth="lg" fullWidth={true} open={open}>
            <DialogTitle>{t('Logout')}</DialogTitle>
            <DialogContent  >
                <Typography sx={{ width: '100%', textAlign: 'left' }}>{t('Are you sure you gonna logout ?')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{t('Cancel')}</Button>
                <Button onClick={logoutHandler}>{t('Logout')}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default LogoutDialog;