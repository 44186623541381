import React, { useState, useContext, Fragment } from "react";
import { Radio, RadioGroup, Box, Typography, FormControlLabel, FormLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckoutContext from "../../store/checkout-context";

const CitiesBranchesSelector = (props) => {
    const checkoutCtx = useContext(CheckoutContext);
    const [accordionExpanded, setAccordionExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        checkoutCtx.setCity(panel);
        setAccordionExpanded(isExpanded ? panel : false);
    };

    return <RadioGroup
        sx={{ marginBottom: '15px' }}
        aria-labelledby="demo-radio-buttons-group-label"
        value={props.selectedBranchId ?? ''}
        name="radio-buttons-group"
        onChange={(e) => props.onBranchChange(e)}
    >
        {
            props.cities.map((city, i) => {

                return (<Fragment key={city.id}>
                { city.branches.filter((branch)=>branch.pickup === 1).length > 0 && <Accordion expanded={accordionExpanded === city.id}  onChange={handleAccordionChange(city.id)} sx={{ margin: '0 !important', border: '1px solid #eee' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{city.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {city.branches.filter((branch)=>branch.pickup === 1).map((branch, ii) => {
                            // console.log('brannnnnch',branch);
                            return <Box  key={ii}><FormControlLabel value={branch.id ?? ''} control={<Radio  />} label={branch.name} /></Box>
                        })}

                    </AccordionDetails>
                </Accordion>}
                </Fragment>)
            })
        }

    </RadioGroup>
};

export default CitiesBranchesSelector;