import { Box, Grid, Typography } from "@mui/material"
import Styles from './styles.module.css';
import { Link } from "react-router-dom";
import config from "../../../../config/config";
import NoImage from '../../../../assets/images/no-image.jpg';
import { getTranslation } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import { globalSetting } from "../../../../constants/settings";


const CategoriesGrid = (props) => {
    const { t, i18n } = useTranslation();

    return <Box sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Grid data-cy="categories-grid-parent" container flexWrap columnSpacing={'10px'} rowSpacing={'20px'}>
            {props.items.length > 0 && props.items.slice(0,globalSetting.home.categories.count).map((item, i) => {
                return (<Grid item xs={globalSetting.home.categories.cols} key={i}>
                    <Link to={`/categories/${item.id}`} className={Styles['cat__item']}>

                        {item.image && <img src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.name} className={[Styles["cat__item--img"],Styles[`${globalSetting.home.categories.rounded ? 'cat_item_rounded' : null}`]].join(' ')} />}
                        {!item.image && <img src={NoImage} alt={item.name} className={Styles["cat__item--img"]} />}
                        <Typography
                            variant="subtitle1"
                            component="h6"
                            fontWeight="500"
                            fontSize={12}
                            lineHeight={1.3}
                            color="#172327"
                            className={Styles["cat__item--title"]}
                        >
                            {getTranslation(item, i18n.resolvedLanguage)['name']}
                        </Typography>
                    </Link>
                </Grid>)
            })}
        </Grid>

    </Box>
}

export default CategoriesGrid;