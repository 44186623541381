import { Box, Paper, Grid, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OrderItem = ({ order }) => {
    const { t, i18n } = useTranslation();
    return (<Paper sx={{ mb: '16px', p: '16px' }}>
        <Box>
            <Typography fontSize={14} fontWeight="bold" sx={{ mb: '5px' }}>{t('Order number')} : {order?.inv_num} </Typography>
            <Typography color="#172327" fontSize={12} sx={{ mb: '2px' }}>{t('Time and Date')} : {(moment(new Date(order?.created_at)).locale(i18n.resolvedLanguage).format('DD-MM-YYYY,  hh:mm A'))}</Typography>
            <Typography color="#172327" fontSize={12} sx={{ mb: '15px' }}>{t('Total')} : {t('EGP')} {order?.total} </Typography>
        </Box>
        <Grid container columnSpacing={1}>
            <Grid item sx={{flex:1}}>
                <Button variant="outlined" disableElevation={true} fullWidth component={Link} to={`/orders/${order?.id}`}>{t('View Order')}</Button>
            </Grid>
          <Grid item  sx={{flex:1}}>
                {(order.status === 'completed') && <Button variant="contained" disableElevation={true} fullWidth component={Link} to={`/review-order/${order?.id}`}>{t('Review')}</Button>}
                {(order.status !== 'completed') && <Button variant="contained" disableElevation={true} fullWidth component={Link} to={`/track-order/${order?.id}`}>{t('Track Order')}</Button>}
            </Grid>
        </Grid>
    </Paper>)
}

const OrdersList = (props) => {
    return (<Box sx={{ px: '16px', }}>
        {
            props.orders.map((order, i) => {
                return (<OrderItem key={i} order={order} />)
            })
        }
        <Box sx={{ height: '50px' }} />

    </Box>);
};

export default OrdersList;