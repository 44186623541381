import { useState, useEffect, useReducer, useRef, useContext } from 'react';
import Styles from './ProductDetailsPage.module.css';
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CartContext from '../../store/cart-context';
import CheckoutContext from '../../store/checkout-context';
import useProduct from '../../hooks/useProduct';
import OptionsSelector from './components/OptionsSelector';
import ExtrasSelectorSimple from './components/ExtrasSelectorSimple';
import ExtrasSelectorQuantity from './components/ExtrasSelectorQuantity';
import ProductsDetailsAppBar from './components/Appbar';
import ProductHeader from './components/ProductHeader';
import ProductImage from './components/ProductImage';
import ProductFooter from './components/ProductFooter';
import ProductNote from './components/ProductNote';

const totalReducer = (state, action) => {
    if (action.type === 'optionExtras') {
        return {
            sizeTotal: state.sizeTotal || 0,
            optionExtrasTotal: action.payload.price ,
            // generalExtrasTotal: state.generalExtrasTotal,
            generalExtras: state.generalExtras || 0
        }
    };
    if (action.type === 'size') {
        return {
            sizeTotal: parseInt(action.payload.price),
            optionExtrasTotal: 0,
            // generalExtrasTotal: 0,
            generalExtras: state.generalExtras || 0
        }
    }
    if (action.type === 'generalExtras') {
        return {
            sizeTotal: state.sizeTotal || 0,
            optionExtrasTotal: state.optionExtrasTotal || 0,
            // generalExtrasTotal: action.payload.price,
            generalExtras: parseInt(action.payload.price)
        }
    }
}

const ProductDetailsPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const productQuery = useProduct(id);
    const wrapRef = useRef();

    const [qty, setQty] = useState(1);
    const { t } = useTranslation();

    const [itemTotal, dispatchItemTotals] = useReducer(totalReducer, { sizeTotal: null, optionExtrasTotal: null, generalExtrasTotal: null, });

    const [optionExtras, setOptionExtras] = useState([]);
    const [optionExtrasMeta, setOptionExtrasMeta] = useState();
    const [optionExtrasTotalQty, setOptionExtrasTotalQty] = useState(0);
    const [selectedOptionExtras, setSelectedOptionExtras] = useState([]);
    const [selectedOptionExtrasID, setSelectedOptionExtrasID] = useState([]);

    const [generalExtras, setGeneralExtras] = useState([]);
    const [generalExtrasMeta, setGeneralExtrasMeta] = useState();
    const [generalExtrasTotalQty, setGeneralExtrasTotalQty] = useState(0);
    const [selectedGeneralExtras, setSelectedGeneralExtras] = useState([]);
    const [selectedGeneralExtrasID, setSelectedGeneralExtrasID] = useState([]);

    const [selectedSize, setSelectedSize] = useState();


    const checkoutCtx = useContext(CheckoutContext);
    const cartCtx = useContext(CartContext);

    const [appBarScrolling, setAppBarScrolling] = useState(false);

    const [note, setNote] = useState();

    // console.log('products details', productQuery.data);

    useEffect(() => {
        if (productQuery.data?.item?.type === 'variable') {
            dispatchItemTotals({ type: 'size', payload: { price: 0 } });
        } else {
            if (productQuery.data?.options && productQuery.data?.options?.length === 1) {
                fetchOptionExtras(productQuery.data?.options[0]['id']);
                dispatchItemTotals({ type: 'size', payload: { price: productQuery.data?.options[0]['price'] } });
                if (productQuery.data?.options?.length > 1) {
                    setSelectedSize({ label: productQuery.data?.options[0]['option_name'], value: productQuery.data?.options[0]['id'] });
                } else {
                    setSelectedSize({ label: t('Regular'), value: productQuery.data?.options[0]['id'] });
                };
            }

        };

        return () => {
            dispatchItemTotals({ type: 'size', payload: { price: null } });
            setOptionExtras([]);
            setSelectedSize({ label: null, value: null });
            setSelectedOptionExtras([]);
            setQty(1);
        };


    }, [productQuery.data?.item, productQuery.data?.options]);

    useEffect(() => {
        
        if(productQuery.data?.extras_with_options){
            // console.log(productQuery.data?.extras_with_option);
            setGeneralExtrasMeta(productQuery.data?.extras_with_options);
            if (productQuery.data?.extras_with_options.style === 'quantity') {
                const modifiedList = productQuery.data?.extras_with_options?.extras?.map((extra) => {
                    return { ...extra, qty: 0 };
                });
                // console.log(productQuery.data?.extras_with_options);
                setGeneralExtras(modifiedList);
            };
        }
       


    }, [productQuery.data]);


    const fetchOptionExtras = (id) => {
        setOptionExtras([]);
        setSelectedOptionExtras([]);
        let fetchedOption = productQuery.data?.options.find((item) => item.id == id);
        if (fetchedOption?.extra) {
            setOptionExtras(fetchedOption?.extra?.extras?.map(extra => {
                return { ...extra, qty: extra.qty || 0 };
            }) || []);
            setOptionExtrasMeta({
                minimum: fetchedOption?.extra?.minimum,
                maximum: fetchedOption?.extra?.maximum,
                style: fetchedOption?.extra?.style,
                category_name: fetchedOption?.extra?.category_name
            });
        };

    }

    const onOptionChange = (e) => {
        let selectedItem = productQuery.data?.options.find(item => item.id == e.target.value);
        setSelectedSize({ label: selectedItem.option_name, value: selectedItem.id });
        fetchOptionExtras(selectedItem.id);
        setSelectedOptionExtras([]);
        setOptionExtrasTotalQty(0);
        dispatchItemTotals({ type: 'size', payload: { price: selectedItem.price } });
        setSelectedOptionExtrasID([]);
    }


    const onOptionExtraChange = (e) => {
        let selectedItem = optionExtras.find(item => item.id == e.target.value);
        if (e.target.checked) {
            setSelectedOptionExtras(prev => {
                let storedExtraIndex = prev?.findIndex(item => e.target.value == item.id);
                if (storedExtraIndex === -1 && optionExtrasMeta.maximum > prev.length) {
                    const updatedExtrasArray = [...prev, {
                        extra_name: selectedItem.extra_name,
                        id: selectedItem.id,
                        qty: selectedItem.qty || 1,
                        extra_price: selectedItem.extra_price,
                        totalPrice: selectedItem.extra_price
                    }];
                    setSelectedOptionExtrasID(updatedExtrasArray.map(item => item.id));
                    setOptionExtrasTotalQty(updatedExtrasArray.length);
                    const totalExtrasPrice = updatedExtrasArray.reduce((total, item) => {
                        return total + item.extra_price;
                    }, 0);

                    dispatchItemTotals({ type: 'optionExtras', payload: { price: totalExtrasPrice } });
                    return updatedExtrasArray;
                } else {
                    setOptionExtrasTotalQty(prev.length);
                    return prev;
                };
            });
        } else {
            setSelectedOptionExtras(prev => {
                const filteredItems = prev?.filter(item => item.id !== selectedItem.id);
                setOptionExtrasTotalQty(filteredItems.length);
                const filteredExtrasPrice = filteredItems.reduce((total, item) => {
                    return total + item.extra_price;
                }, 0);
                setSelectedOptionExtrasID(filteredItems.map(item => item.id));
                dispatchItemTotals({ type: 'optionExtras', payload: { price: filteredExtrasPrice } });
                return filteredItems;
            })
        }
    }

    const onGeneralExtraChange = (e) => {


        let selectedItem = productQuery.data?.extras_with_options?.extras.find(item => item.id == e.target.value);
        if (e.target.checked) {
            setSelectedGeneralExtras(prev => {
                let storedExtraIndex = prev?.findIndex(item => e.target.value == item.id);
                if (storedExtraIndex === -1 && productQuery.data?.extras_with_options?.maximum > prev.length) {
                    const updatedExtrasArray = [...prev, {
                        extra_name: selectedItem.extra_name,
                        id: selectedItem.id,
                        qty: selectedItem.qty || 1,
                        extra_price: selectedItem.extra_price,
                        totalPrice: selectedItem.extra_price
                    }];
                    setSelectedGeneralExtrasID(updatedExtrasArray.map(item => item.id));
                    setGeneralExtrasTotalQty(updatedExtrasArray.length);
                    const totalExtrasPrice = updatedExtrasArray.reduce((total, item) => {
                        return total + item.extra_price;
                    }, 0);

                    dispatchItemTotals({ type: 'generalExtras', payload: { price: totalExtrasPrice } });
                    return updatedExtrasArray;
                } else {
                    setGeneralExtrasTotalQty(prev.length);
                    return prev;
                };
            });
        } else {
            setSelectedGeneralExtras(prev => {
                const filteredItems = prev?.filter(item => item.id !== selectedItem.id);
                setGeneralExtrasTotalQty(filteredItems.length);
                const filteredExtrasPrice = filteredItems.reduce((total, item) => {
                    return total + item.extra_price;
                }, 0);
                setSelectedGeneralExtrasID(filteredItems.map(item => item.id));
                dispatchItemTotals({ type: 'generalExtras', payload: { price: filteredExtrasPrice } });
                return filteredItems;
            })
        }
    }




    const qtyIncHandler = () => {
        setQty(prev => {
            return prev + 1;
        })
    }

    const qtyDecHandler = () => {
        setQty(prev => {
            if (prev <= 1) {
                return 1
            } else {
                return prev - 1;
            }
        })
    };


    const optionExtraQtyIncHandler = (extra) => {

        setOptionExtras(prev => {
            const extraIndex = prev.findIndex(item => item.id === extra.id);
            prev[extraIndex] = { ...extra, qty: extra.qty + 1 };
            return [...prev];
        });

        setSelectedOptionExtras(prev => {
            const extraIndex = [...prev].findIndex(item => item.id === extra.id);
            if (extraIndex === -1) {
                prev.push({ ...extra, qty: extra.qty + 1, extraTotal: extra.extra_price });
                setSelectedOptionExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                setOptionExtrasTotalQty((prev) => {
                    return prev + 1;
                });
                // console.log('totalExtrasPrice -1', totalExtrasPrice);
                dispatchItemTotals({ type: 'optionExtras', payload: { price: totalExtrasPrice } });
            } else {
                prev[extraIndex] = { ...extra, qty: extra.qty + 1, extraTotal: (extra.qty + 1) * extra.extra_price };
                setOptionExtrasTotalQty((prev) => {
                    return prev + 1;
                });
                setSelectedOptionExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                // console.log('totalExtrasPrice exist', totalExtrasPrice);
                dispatchItemTotals({ type: 'optionExtras', payload: { price: totalExtrasPrice } });
            }
            return [...prev];
        });

    }

    const optionExtraQtyDecHandler = (extra) => {
        setOptionExtras(prev => {
            const extraIndex = prev.findIndex(item => item.id === extra.id);
            prev[extraIndex] = { ...extra, qty: extra.qty > 0 ? extra.qty - 1 : 0 };
            return [...prev];
        });

        setSelectedOptionExtras(prev => {
            extra.qty = extra.qty - 1;
            const extraIndex = prev.findIndex(item => item.id === extra.id);

            if (extra.qty > 0) {

                prev[extraIndex] = { ...extra, qty: extra.qty, extraTotal: extra.qty * extra.extra_price };
                setSelectedOptionExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                dispatchItemTotals({ type: 'optionExtras', payload: { price: totalExtrasPrice } });
                setOptionExtrasTotalQty((prev) => {
                    return prev - 1;
                });


                return [...prev];
            } else {
                const filtered = prev.filter(item => item.id !== extra.id);
                setSelectedOptionExtrasID(prev => {
                    return prev.filter(item => item !== extra.id);
                });

                const totalExtrasPrice = [...filtered].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                dispatchItemTotals({ type: 'optionExtras', payload: { price: totalExtrasPrice } });
                setOptionExtrasTotalQty((prev) => {
                    return prev - 1;
                });
                return filtered;
            }


        });

    }


    const generalExtraQtyIncHandler = (extra) => {
        // console.log(extra);
        setGeneralExtras(prev => {
            const extraIndex = prev.findIndex(item => item.id === extra.id);
            prev[extraIndex] = { ...extra, qty: extra.qty ? extra.qty + 1 : 1 };
            return [...prev];
        });

        setSelectedGeneralExtras(prev => {
            const extraIndex = [...prev].findIndex(item => item.id === extra.id);
            // console.log(extraIndex);
            if (extraIndex === -1) {
                prev.push({ ...extra, qty: 1, extraTotal: extra.extra_price });
                setSelectedGeneralExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                setGeneralExtrasTotalQty((prev) => {
                    return prev + 1;
                });
                dispatchItemTotals({ type: 'generalExtras', payload: { price: totalExtrasPrice } });
            } else {
                prev[extraIndex] = { ...extra, qty: extra.qty + 1, extraTotal: (extra.qty + 1) * extra.extra_price };
                setGeneralExtrasTotalQty((prev) => {
                    return prev + 1;
                });
                setSelectedGeneralExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                dispatchItemTotals({ type: 'generalExtras', payload: { price: totalExtrasPrice } });
            }
            return [...prev];
        });

    }

    const generalExtraQtyDecHandler = (extra) => {
        setGeneralExtras(prev => {
            const extraIndex = prev.findIndex(item => item.id === extra.id);
            prev[extraIndex] = { ...extra, qty: extra.qty > 0 ? extra.qty - 1 : 0 };
            return [...prev];
        });

        setSelectedGeneralExtras(prev => {
            extra.qty = extra.qty - 1;
            const extraIndex = prev.findIndex(item => item.id === extra.id);

            if (extra.qty > 0) {

                prev[extraIndex] = { ...extra, qty: extra.qty, extraTotal: extra.qty * extra.extra_price };
                setSelectedGeneralExtrasID(prev.map(item => item.id));
                const totalExtrasPrice = [...prev].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                dispatchItemTotals({ type: 'generalExtras', payload: { price: totalExtrasPrice } });
                setGeneralExtrasTotalQty((prev) => {
                    return prev - 1;
                });


                return [...prev];
            } else {
                const filtered = prev.filter(item => item.id !== extra.id);
                setSelectedGeneralExtrasID(prev => {
                    return prev.filter(item => item !== extra.id);
                });

                const totalExtrasPrice = [...filtered].reduce((total, item) => {
                    return total + item.extraTotal;
                }, 0);
                dispatchItemTotals({ type: 'generalExtras', payload: { price: totalExtrasPrice } });
                setGeneralExtrasTotalQty((prev) => {
                    return prev - 1;
                });
                return filtered;
            }


        });

    }

    const addToCartHandler = () => {

        if (checkoutCtx.deliveryType) {
            cartCtx.notifShow();
            let totalPrice = (itemTotal.sizeTotal + itemTotal.optionExtrasTotal + itemTotal.generalExtras);
            let cartObj = {
                cid: Math.random(),
                id: selectedSize?.value,
                name: productQuery.data?.item?.name,
                extras: selectedOptionExtras,
                generalExtras: selectedGeneralExtras,
                price: totalPrice,
                qty: qty,
                size: selectedSize,
                img: productQuery.data?.item?.image,
                note: note || null
            };

            cartCtx.add(cartObj);
            navigate(-1);
        } else {
            navigate('/delivery');
        }

    }

    const handleScroll = () => {
        const scrollTop = wrapRef.current.scrollTop;
        if (scrollTop > 60) {
            setAppBarScrolling(true);
        } else {
            setAppBarScrolling(false);
        }
    };


    const productNoteHandler = (e) => {
        setNote(e.target.value);
    }


    return <div onScroll={() => handleScroll()} ref={wrapRef} style={{ backgroundColor: "var(--backgroundColor)", height: '100%', overflowY: 'auto' }}><Box id="ProductDetailsPage" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >

        <ProductsDetailsAppBar title={t(productQuery.data?.item?.name)} appBarScrolling={appBarScrolling} />

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', paddingBottom: '150px' }}>
            <ProductImage data={productQuery.data} isLoading={productQuery.isLoading} />
            <Box sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: "var(--backgroundColor)", marginTop: (!productQuery.data?.item?.image) ? '-140px' : 0 }} className={Styles.ProductDetailsPage_content}>
                <Box className={Styles.ProductDetailsPage_notch}></Box>

                <ProductHeader
                    itemTotals={itemTotal}
                    isLoading={productQuery.isLoading}
                    data={productQuery.data}
                    qty={qty}
                    enableQty={selectedSize?.value}
                    onDecrement={qtyDecHandler}
                    onIncrement={qtyIncHandler} />

                {(!productQuery.isLoading && productQuery.data?.options && productQuery.data?.options?.length > 1) && <OptionsSelector
                    title={t('Options')}
                    options={productQuery.data?.options}
                    hidePrice={productQuery.data?.item?.hide_price}
                    onChange={onOptionChange} />}

                {/* option based Extras */}

                {(optionExtras?.length > 0 && optionExtrasMeta.style === 'simple') && <ExtrasSelectorSimple
                    meta={optionExtrasMeta}
                    extras={optionExtras}
                    hidePrice={productQuery.data?.item?.hide_price}
                    selectedExtras={selectedOptionExtrasID}
                    onChange={onOptionExtraChange} />}

                {(optionExtras?.length > 0 && optionExtrasMeta.style === 'quantity') && <ExtrasSelectorQuantity
                    extras={optionExtras}
                    meta={optionExtrasMeta}
                    extrasTotalQty={optionExtrasTotalQty}
                    hidePrice={productQuery.data?.item?.hide_price}
                    onIncrement={optionExtraQtyIncHandler}
                    onDecrement={optionExtraQtyDecHandler} />

                }

                {/* general extras */}



                {selectedSize?.value && (productQuery.data?.extras_with_options?.extras?.length > 0 && productQuery.data?.extras_with_options?.style === 'simple') && <ExtrasSelectorSimple
                    meta={productQuery.data?.extras_with_options}
                    extras={productQuery.data?.extras_with_options?.extras}
                    hidePrice={productQuery.data?.item?.hide_price}
                    selectedExtras={selectedGeneralExtrasID}
                    onChange={onGeneralExtraChange} />}


                {selectedSize?.value && (generalExtras && productQuery.data?.extras_with_options?.style === 'quantity') && <ExtrasSelectorQuantity

                    extras={generalExtras}
                    meta={productQuery.data?.extras_with_options}
                    extrasTotalQty={generalExtrasTotalQty}
                    hidePrice={productQuery.data?.item?.hide_price}
                    onIncrement={generalExtraQtyIncHandler}
                    onDecrement={generalExtraQtyDecHandler} />
                }


                <ProductNote onChange={productNoteHandler} />

                {/* {JSON.stringify(selectedGeneralExtras)} */}

                <ProductFooter
                    productId={id}
                    extrasMeta={optionExtrasMeta}
                    itemTotal={itemTotal}
                    generalExtrasQty={generalExtrasTotalQty}
                    generalExtrasMeta={generalExtrasMeta}
                    extrasTotalQty={optionExtrasTotalQty}
                    qty={qty}
                    onAddToCart={addToCartHandler}
                />

            </Box>
        </Box>
    </Box>
    </div>
};

export default ProductDetailsPage;