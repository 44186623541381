import { useState, useEffect, useContext } from 'react';
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { Box, Typography, Rating, Button, Dialog, DialogActions, TextField, DialogTitle } from '@mui/material';
import { useTranslation } from "react-i18next";
import axiosInstance from '../../config/axios';
import AuthContext from '../../store/auth-context';
import { useParams, useNavigate } from 'react-router-dom';
import CustomerContext from '../../store/customer-context';
import useOrder from '../../hooks/useOrder';

const OrderReviewPage = () => {

    const { t, i18n } = useTranslation();
    const [foodQualityrating, setFoodQualityRating] = useState(0);
    const [costRating, setCostRating] = useState(0);
    const [serviceRating, setServiceRating] = useState(0);
    const [note, setNote] = useState();
    const authCtx = useContext(AuthContext);
    const customerCtx = useContext(CustomerContext)
    const { id } = useParams();
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const orderQuery = useOrder(id);


    const handleReviewDialogSubmit = async () => {
        try {
            const reviewData = {
                order_id: id,
                name: customerCtx.info?.name,
                email: 'sample@mail.com',
                phone: customerCtx.info?.phone,
                food: foodQualityrating,
                cost: costRating,
                service: serviceRating,
                feedback: note
            };

            await axiosInstance.post(`/feedback/post/${orderQuery.data?.branch_id}`, reviewData);

            setIsSubmitted(true);
            setTimeout(() => {
                navigate(-1);
                setIsSubmitted(false);
            }, 5000);

        } catch (error) {
            console.log('feedbackRes error', error);
        }
    };

    return <><Box className="h-100">
        <CustomAppBar title={t("Review")} notif hasBack />
        {!isSubmitted && <Box sx={{ p: '16px' }}>


            <Box sx={{ py: '5px' }}>
                <Typography component="legend">{t('Food Quality')}</Typography>
                <Rating
                    name="simple-controlled"
                    size="large"
                    value={foodQualityrating}
                    onChange={(event, newValue) => {
                        setFoodQualityRating(newValue);
                    }}
                />
            </Box>
            <Box sx={{ py: '5px' }}>
                <Typography component="legend">{t('Cost')}</Typography>
                <Rating
                    name="simple-controlled"
                    value={costRating}
                    size="large"
                    onChange={(event, newValue) => {
                        setCostRating(newValue);
                    }}
                />
            </Box>
            <Box sx={{ py: '5px', mb: '16px' }}>
                <Typography component="legend">{t('Service')}</Typography>
                <Rating
                    name="simple-controlled"
                    value={serviceRating}
                    size="large"
                    onChange={(event, newValue) => {
                        setServiceRating(newValue);
                    }}
                />
            </Box>
            <Box sx={{ pb: '5px', marginBottom: '16px' }}>
                <TextField id="outlined-basic" label={t("Review")} variant="outlined" fullWidth rows={5} multiline onChange={(e) => setNote(e.target.value)} />

            </Box>

            <Button disabled={!costRating || !foodQualityrating || !serviceRating} onClick={handleReviewDialogSubmit} variant="contained" fullWidth>{t('Submit')}</Button>


        </Box>}
        {isSubmitted && <Box sx={{ p: '16px' }}>
            <Typography>{t('Feedback Created Successfully')}</Typography>
        </Box>}
    </Box>
    </>
};

export default OrderReviewPage;
