import React, { useEffect, useState } from 'react';
import axiosInstance from '../config/axios';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const InfoContext = React.createContext(
    {
        info: undefined,
        tax: undefined,
        loadingInfo: true,
        closed: null,
        languages: undefined,
        defaultLanguage: undefined,
        isTrial: false,
        refetch: () => { }
    }
);



export const InfoContextProvider = (props) => {

    const { i18n } = useTranslation();
      const [infoData, setInfoData] = useState();
    const [taxData, setTaxData] = useState();
    const [langsArray, setlangsArray] = useState();
    const [defaultLanguage, setDefaultLanguage] = useState(localStorage.getItem('initLang'));
    const [infoLoading, setInfoLoading] = useState();
    const [isClosed, setIsClosed] = useState();

    const fetchAll = () => {
        axios.all([
            axiosInstance.get(`/${i18n.resolvedLanguage}/business/info`),
            axiosInstance.get(`/languages`),
            axiosInstance.get(`/${i18n.resolvedLanguage}/tax`)
        ]).then(axios.spread((info, languages, taxes) => {
            setInfoData(info.data.info);
            setTaxData(taxes.data.tax);
            setlangsArray(languages.data.languages);
            setInfoLoading(false);
            const defaultLang = languages.data.languages.find((lang) => lang.default === 1);
            if (defaultLang) {
                setDefaultLanguage(defaultLang['language_code']);
                if (! localStorage.getItem('initLang')) {
                    localStorage.setItem('initLang', defaultLang['language_code']);
                    i18n.changeLanguage(defaultLang['language_code']);
                }
            };
        })).catch(error=>{
            setInfoLoading(false);
        })
    }

    useEffect(() => {
        fetchAll();
    }, [i18n.resolvedLanguage]);

    const checkClosed = async () => {

        let timeNow = new Date()
        timeNow = timeNow.toISOString().split('T')[0] + ' ' + timeNow.getHours() + ':' + timeNow.getMinutes();

        const openRes = await axiosInstance.post(`/working-hours/check`, {
            time: timeNow
        });

        if (openRes.data?.open === 1) {
            setIsClosed(false);
        } else {
            setIsClosed(true);
        };


    };

    useEffect(()=>{
        checkClosed();
        setInterval(() => {
          checkClosed();
        }, 60000)
    
    },[]);

    const fetchInfo = async () => {
        try {
            const infoRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/business/info`);
            setInfoData(infoRes.data.info);
            // console.log('infoctx',infoRes.data.info);
        } catch (error) {
            console.log('info res error', error);
        }

    }

    const ctx = {
        info: infoData,
        loadingInfo: infoLoading,
        tax: taxData,
        closed: isClosed,
        refetch: () => fetchInfo(),
        languages: langsArray,
        defaultLanguage:defaultLanguage,
        isTrial: infoData?.sms === 'active' ? false : true
    };

    return <InfoContext.Provider value={ctx}>{props.children}</InfoContext.Provider>
}

export default InfoContext;