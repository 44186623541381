import React, { useReducer, useState } from 'react';

const CartContext = React.createContext({
    items: [],
    total: 0,
    update: (item) => { },
    add: (item) => { },
    remove: (cid) => { },
    clear:()=>{},
    notifShow: ()=>{},
    notifHide:()=>{},
    notifActive: false
});

const cartReducer = (state, action) => {
    let updatedItems = state.items;
    let updatedTotal = state.total;
    if (action.type === 'ADD') {

        let itemIndex = state.items.findIndex(item => item.cid === action.item.cid);

        if (itemIndex === -1) {
            updatedItems = [...state.items, action.item];
            updatedTotal = updatedItems.reduce((a,b)=>{
                return a + (b.price * b.qty);
            },0);
        } else {
            let item = state.items.find(item => item.cid === action.item.cid);
            let itemUpdated = { ...item, qty: item.qty + action.item.qty , price: action.item.price };
            updatedItems[itemIndex] = itemUpdated;
         };

        updatedTotal = updatedItems.reduce((a,b)=>{
            return a + (b.price * b.qty);
         },0);

        localStorage.setItem('rdx_cart', JSON.stringify({ items: updatedItems, total: updatedTotal }));

        return {
            items: updatedItems,
            total: updatedTotal
        }

    } else if (action.type === 'UPDATE') {

        let updatedTotal ;
        let itemUpdated ;
        let itemIndex = state.items.findIndex(item => item.cid === action.item.cid);
        let item = state.items.find(item => item.cid === action.item.cid);
      
        itemUpdated  = { ...item, qty: action.item.qty, price: parseFloat(action.item.price)  };

        updatedItems[itemIndex] = itemUpdated;
      
        updatedTotal = updatedItems.reduce((a,b)=>{
            return a + (b.price * b.qty);
         },0);

        localStorage.setItem('rdx_cart', JSON.stringify({ items: updatedItems, total: updatedTotal }));
        

        
        return {
            items: updatedItems,
            total: updatedTotal
        }

    } else if (action.type === 'REMOVE') {

        let removedItem = state.items.find(item => item.cid === action.cid);
        let updatedTotal ;
        let updatedList = state.items.filter(item => item.cid != action.cid);

        updatedTotal = updatedList.reduce((a,b)=>{
            return a + (b.price * b.qty);
         },0);
        localStorage.setItem('rdx_cart', JSON.stringify({ items: updatedList, total: updatedTotal }));

        return {
            items: updatedList,
            total: updatedTotal
        }



    }else if(action.type === 'CLEAR'){
        localStorage.removeItem('rdx_cart');
        return {
            items: [],
            total: 0
        }
    }
}

export const CartContextProvider = (props) => {

    let storedCart = {items:null,total:0};

 if(localStorage.getItem('rdx_cart') !== null){
    storedCart = localStorage.getItem('rdx_cart');
    storedCart = JSON.parse(storedCart);
 }

    const [ showNotif, setShowNotif ] = useState(false);
    const [cartState, dispatchCart] = useReducer(cartReducer, { items: storedCart.items?? [], total:storedCart.total ?? 0 });

    const ctxValue = {
        items: cartState.items,
        total: cartState.total,
        notifVisible: showNotif,
        add: (item) => {
            dispatchCart({ type: 'ADD', item: item })
        },
        update: (item) => {
            dispatchCart({ type: 'UPDATE', item: item })
        },
        remove: (cid) => {
            dispatchCart({ type: 'REMOVE', cid: cid })
        },
        clear:()=>{
            dispatchCart({ type: 'CLEAR' })
        },
        notifShow:()=>{
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false);
            }, 5000);
        },
        notifHide:()=>{
            setShowNotif(false);  
        },
        notifActive:showNotif

    }

    return <CartContext.Provider value={ctxValue}>{props.children}</CartContext.Provider>
}


export default CartContext;

