import { Box, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CartContext from '../../../../store/cart-context';
import InfoContext from '../../../../store/info-context';

const CartTotals = ()=>{
    const { t } = useTranslation();
    const infoCtx = useContext(InfoContext);
    const cartCtx = useContext(CartContext);

    return (
        <Box sx={{backgroundColor:'#fff',pt:'16px'}}>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography>{t('Subtotal')} ( {cartCtx.items.length} {t('item')} )</Typography></Box>
                <Box><Typography>{t('EGP')} {cartCtx.total}</Typography></Box>
            </Box>
            {/* <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography>Taxes</Typography></Box>
                <Box><Typography>EGP 13.72</Typography></Box>
            </Box>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography>Total</Typography></Box>
                <Box><Typography>EGP 163.72</Typography></Box>
            </Box> */}
        </Box>
    );
};

export default CartTotals;