import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import config from "./config";

const firebaseConfig = {
  apiKey: "AIzaSyADAU_q5AKO-9QRvclUpl6hAXdlxFRXN2M",
  authDomain: "gromenu-cashier.firebaseapp.com",
  projectId: "gromenu-cashier",
  storageBucket: "gromenu-cashier.appspot.com",
  messagingSenderId: "413986815442",
  appId: "1:413986815442:web:08ebf7ddd0c46fe82f9c0b",
  measurementId: "G-WKCLX73750"
};

const certificate = "BHVEElNxwe-l_zesfZUs7EgLJyGXwWuLg0S8HLBQ_iWHnQhDX0IK2hGZNmx3N77zRfsav6O2oLPhXjODnAb8Lqc";
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: certificate })
    .then((currentToken) => {
      if (currentToken) {
        if (config.developmentMode) {
          console.log('current token for client: ', currentToken);
        }
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        if (config.developmentMode) {
          console.log('No registration token available. Request permission to generate one.');
        };
      }
    })
    .catch((err) => {
      if (config.developmentMode) {
        console.log('An error occurred while retrieving token. ', err);
      };
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });