import { useContext } from "react";
import InfoContext from "../../store/info-context";
import { ThemeOptionsContext } from "../../theme/ThemeProvider";
import { Box,Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../utils/helper";
import config from "../../config/config";

const AppOuter = () => {

    const infoCtx = useContext(InfoContext);
    const appearanceCtx = useContext(ThemeOptionsContext);
    const { t, i18n } = useTranslation();

    return <div id='app_outer' style={{ backgroundImage: appearanceCtx.appearance.intro_bg_image ? `url(${config.assets}/${appearanceCtx.appearance.intro_bg_image})` : 'none', backgroundColor: appearanceCtx.appearance?.intro_bg_color ? appearanceCtx.appearance?.intro_bg_color : 'transparent' }}>
        <div className="brand">
            <Box className="intro_brand__logo"  >

                {infoCtx.info?.logo && <img src={config.assets + infoCtx.info?.logo} alt="" className={`intro_brand__logo--img`} />}
                {!infoCtx.info?.logo && <div className={`intro__logo--placeholder`}> <Typography>{getTranslation(infoCtx.info, i18n.resolvedLanguage)?.name}</Typography></div>}
            </Box>
            <Typography textAlign="center" fontWeight="bold" variant="h4" component="h4" sx={{ color: '#fff' }}>{getTranslation(infoCtx.info, i18n.resolvedLanguage)?.info}</Typography>
        </div>
    </div>
};

export default AppOuter;