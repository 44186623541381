import { Card,  Box, Typography, FormControl, RadioGroup, Radio} from "@mui/material";
import { useTranslation } from "react-i18next";


const OptionsSelector = ({title,options, hidePrice,onChange})=>{

    const { t } = useTranslation();

    return (
        <Card elevation={0} sx={{ mx: '15px', my: '5px', padding: '15px', backgroundColor: '#fff' }}><Box sx={{ position: 'relative', zIndex: 5 }}>
                    <Typography variant="h6" component="h5" sx={{ mb: '10px' }}>{title}</Typography>
                    <FormControl fullWidth>
                        {options && options.length > 1 &&
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                {
                                    options?.sort(function (a, b) { return a.price - b.price }).map((option, i) => {

                                        return <Box key={i}>
                                            <label htmlFor={option.option_name?.trim() + option.id} style={{ display: 'flex', alignItems: 'center', width: '100%' }} >
                                                <Radio id={option.option_name?.trim() + option.id} value={option.id} sx={{ pl: 0 }} onChange={onChange} />
                                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                    <Typography>{option.option_name}</Typography>
                                                    {hidePrice === 0 ? <>{option.price > 0 && <Typography>{option.price} {t('EGP')}</Typography>}</> : null}
                                                </Box>
                                            </label>
                                        </Box>
                                    })
                                }
                            </RadioGroup>}
                    </FormControl>
                </Box>
                </Card>
    )
};
export default OptionsSelector;