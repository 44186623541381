const DecreaseIcon = ({ color }) => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.0835 10.4169H12.9168V11.2502H7.0835V10.4169Z" fill={color} />
<path d="M19.5 10.8335V20.3335H10C4.75329 20.3335 0.5 16.0802 0.5 10.8335C0.5 5.58679 4.75329 1.3335 10 1.3335C15.2467 1.3335 19.5 5.58679 19.5 10.8335Z" stroke={color}/>
</svg>

    );
};

export default DecreaseIcon;
