import { useQuery } from "@tanstack/react-query"
import { fetchCategory } from "../providers/categories"
import { useContext } from "react"
import AuthContext from "../store/auth-context"
import { useTranslation } from "react-i18next"

const useCategory = (categoryId)=>{
    const authCtx = useContext(AuthContext);
    const { i18n } = useTranslation();
    const categoryQuery = useQuery({
        queryKey:['category',categoryId,authCtx,i18n],
        queryFn:()=>fetchCategory(categoryId,authCtx,i18n)
    })

    return categoryQuery;
};

export default useCategory;