import React, { useState } from "react";

import { useNavigate, Link } from 'react-router-dom';
import { Button, Typography, FormControl,  OutlinedInput, Grid, Box, useTheme } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import CheckoutStepper from "../../components/CheckoutStepper/CheckoutStepper";


const CheckoutVerifyPage = () => {
    const [phoneVal, setPhoneVal] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const [passwordVal, setPasswordVal] = useState();
    const navigate = useNavigate();
    const theme = useTheme();

    const onPhoneChange = (value) => {
        setPhoneVal(value);
    }

    const onPasswordChange = (value) => {
        setPasswordVal(value);
    }

    return (
        <Box id="CheckoutVerifyPage">
            <CustomAppBar hasBack backDark notif title="Chechout" />
            <Box sx={{ p: '16px' }}>
                <CheckoutStepper step={2} />
                <Box sx={{ mt: '50px' }}>
                    <Typography variant="h6" color="#000" sx={{ mb: '5px' }} component="h2">Confirm phone number</Typography>
                    <Typography variant="body2" color="#07485B" sx={{ mb: '10px' }} component="h2">Get a verification code sent to your phone NUM.</Typography>
                    <Typography variant="body1" color={theme.palette.info.main} fontWeight="bold" fontSize={16} sx={{ mb: '20px' }} component="h2">Verify +02 011 1180 1673</Typography>
                    <Box sx={{ mb: '20px' }}>
                        <Typography variant="body1" color={theme.palette.info.main} fontWeight="bold" fontSize={12} textAlign="center" sx={{ mb: '20px' }} component="h2">Enter the code</Typography>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="filled">
                                    <OutlinedInput id="filled-adornment-mobile" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="filled">
                                    <OutlinedInput id="filled-adornment-mobile" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="filled">
                                    <OutlinedInput id="filled-adornment-mobile" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="filled">
                                    <OutlinedInput id="filled-adornment-mobile" />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography color={theme.palette.info.main}>Didn't receive a code ? <Typography component="span" color="#5E9460">Resend</Typography></Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography color={theme.palette.info.main} textAlign="right">00:50</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>


                <Box sx={{ mt: '50px' }}>
                    <Button disableElevation sx={{mb:'16px'}} fullWidth size="large" variant="contained" onClick={() => navigate('/checkout-confirm')}>Verify</Button>
                    <Button disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={() => navigate('/cart')}>Cancel</Button>
                </Box>
            </Box>
        </Box>
    )
};

export default CheckoutVerifyPage;