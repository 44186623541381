import { Card,  Box, Typography, FormControl,FormGroup,Checkbox,Alert} from "@mui/material";
import { useTranslation } from "react-i18next";

const ExtrasSelectorSimple = ({ extras, meta ,selectedExtras ,hidePrice, onChange,id }) => {
    const { t,i18n } = useTranslation();


    return (
        <Card elevation={0} sx={{ mx: '15px', my: '5px', padding: '15px' }}><Box sx={{ position: 'relative', zIndex: 5 }}>
      
            <Typography variant="h6" component="h5" sx={{ mb: '10px' }}>{meta?.category_name}</Typography>
            {(meta && meta.maximum >= 0 && meta.minimum >= 0 && i18n.resolvedLanguage === 'en') && <Alert severity="info" sx={{ marginBottom: '15px' }}> Minimum quantity {meta.minimum} and maximum {meta.maximum}</Alert>}
            {(meta && meta.maximum >= 0 && meta.minimum >= 0 && i18n.resolvedLanguage === 'ar') && <Alert severity="info" sx={{ marginBottom: '15px' }}> قم باختيار {meta.minimum} كحد ادني و {meta.maximum} كحد اقصي</Alert>}
            <FormControl fullWidth>
                <FormGroup
                    aria-labelledby="demo-radio-buttons-group-label" >
                    {extras.map((extra) => {
                        const rnderId = Math.random(); 
                        return (<Box key={`extra_${extra.id}`}>
                            <label htmlFor={`extra_${extra.id}`} style={{ display: 'flex', alignItems: 'center', width: '100%' }} >
                                <Checkbox disabled={selectedExtras.length === meta.maximum && !selectedExtras.includes(extra.id)} id={`extra_${extra.id}`} value={extra.id} sx={{ pl: 0 }} onChange={onChange} />
                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                    <Typography>{extra.extra_name}</Typography>
                                    {hidePrice === 0 ? <Typography>{extra.extra_price} {t('EGP')}</Typography> : null}
                                </Box>
                            </label>
                        </Box>)
                    })}
                </FormGroup>
            </FormControl>
        </Box></Card>
    )
};

export default ExtrasSelectorSimple;