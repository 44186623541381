import Styles from './style.module.css';
import { Paper, Grid, Box } from '@mui/material';

const ProductItemSkeleton = () => {

    return <Paper className={Styles.ProductItem} elevation={3}>
        <Box component="div">

            <div className={`${Styles.image} skeleton`}></div>


            <Box className="p-16">
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}> 
                    <div className={`${Styles.title} skeleton`}></div>
                    <div className={`${Styles.price} skeleton`}></div>
                </div>

                <div className={`${Styles.desc}`}>
                    <span className='skeleton'></span>
                    <span className='skeleton'></span>
                    {/* <span className='skeleton'></span> */}
                </div>

            </Box>

        </Box>
    </Paper>

}

const ProductsFullWidthSkeleton = ({ length }) => {
    const skeletonData = Array.from(Array(length ? length : 6).keys());
    return (
        <Box sx={{ px: '16px',display:'flex' }}>
            {skeletonData.map((item, i) => {
                return <ProductItemSkeleton key={i} />
            })}
        </Box>
    )

}

export default ProductsFullWidthSkeleton;