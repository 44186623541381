import BottomTabs from "../../components/BottomTabs/BottomTabs.js";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { Box } from "@mui/material";
import OrdersList from "./Components/OrdersList/OrdersList";
import { useTranslation } from "react-i18next";
import AuthContext from '../../store/auth-context';
import OrdersEmpty from './Components/OrdersEmpty.js';
import OrdersSkeleton from '../../components/skeleton/OrdersSkeleton/OrdersSkeleton.js';
import useOrders from '../../hooks/useOrders.js';
import { useContext } from "react";
import OrderError from "./Components/OrdersError.js";

const OrdersPage = () => {
  const { t } = useTranslation();
  const ordersQuery = useOrders();
  const authCtx = useContext(AuthContext);

  return <><Box id="CartPage" className="h-100">
    <CustomAppBar title={t("Ordering")} notif />

    {!ordersQuery.isLoading && authCtx.isLoggedIn && ordersQuery.data?.length > 0 && <OrdersList orders={ordersQuery.data} />}
    {!ordersQuery.isLoading && ordersQuery.data?.length === 0 && authCtx.isLoggedIn && <OrdersEmpty />}
    {!ordersQuery.isLoading && !authCtx.isLoggedIn && <OrderError />}
    {ordersQuery.isLoading && <OrdersSkeleton />}
  </Box>
    <BottomTabs />
  </>
};

export default OrdersPage;
