import { useEffect, useContext } from "react";
import InfoContext from "../../store/info-context";
import { Button, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const ClosedBanner = () => {

    const infoCtx = useContext(InfoContext);
    const { t } = useTranslation();

    return <>
        {(!infoCtx.closed && infoCtx.info?.temp_closed === 1) && <Alert severity="error" sx={{ borderRadius:'0' }} >{t('Temporary Closed!')}</Alert>}
        {infoCtx.closed === true && <Alert variant="filled" severity="error" sx={{ borderRadius:'0' }} action={
            <Button component={Link} to="/contacts" color="inherit" size="small">
                {t('Working Hours')}
            </Button>
        }>{t('Closed Now!')}</Alert>}
    </>
};

export default ClosedBanner;