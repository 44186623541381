import { useReducer, useContext, useState, useEffect } from 'react';
import { Box, Typography, FormControl, OutlinedInput, InputAdornment, Button } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegisterationContext from '../../../store/register-context';
import AuthWrapper from '../../../components/AuthWrapper/AuthWrapper';
import AuthContext from '../../../store/auth-context';

const phoneReducerFunc = (state, action) => {
    const regexPattern = /^01[0125][0-9]{8}$/;
    return { value: action.value, invalid: action.value ? !regexPattern.test(0 + action.value) : false, required: action.value.length === 0 }
}

const ConfirmPhonePage = () => {

    const authCtx = useContext(AuthContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [phoneVal, dispatchPhone] = useReducer(phoneReducerFunc, { value: '', invalid: undefined, required: undefined })
    const registerationContext = useContext(RegisterationContext);
    const [phoneExist, setPhoneExist] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(true);


    const onPhoneChange = (e) => {
        let phone = e.target.value;
        if (phone.charAt(0) === '0') {
            phone = phone.substring(1);
        };
        dispatchPhone({ value: phone });

        if (phoneVal !== phone) {
            setSubmitDisabled(false);
            setPhoneExist(false);
        }
    }



    const onSubmitHandler = () => {
        authCtx.clearOtp();
        if (phoneVal.value || !phoneExist) {
            registerationContext.accountExists(phoneVal.value).then(data => {
                authCtx.sendOtp(phoneVal.value);
                navigate('/verify');
                // if (data.valid === true) {
                //     setPhoneExist(true);
                //     setSubmitDisabled(true);
                // } else {
                //     setPhoneExist(false);
                //     setSubmitDisabled(false);
                //     sendOtpHandler();

                // }
            });
        };
    }

    useEffect(()=>{
        authCtx.clearOtp();
    },[]);

    return (<AuthWrapper>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t("Verify Phone")}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}> {t("You'll receive a one time verification code by SMS shortly.")} </Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <FormControl fullWidth variant="outlined">
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                    <img src={require('../../../assets/images/phoneIcon.png')} style={{ marginRight: i18n.resolvedLanguage === 'en' ? '10px' : '0px', marginLeft: i18n.resolvedLanguage === 'ar' ? '10px' : '0px' }} />
                    <Typography color="#555" fontSize="14px">{t('Mobile number')}</Typography>
                </Box>

                {i18n.resolvedLanguage === 'en' && <OutlinedInput
                    type="tel"
                     color='dark'
                    id="outlined-adornment-mobile"
                    value={phoneVal.value}
                    placeholder={t('Enter your mobile number...')}
                    startAdornment={<InputAdornment position="start"><Box sx={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ borderRight: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingRight: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../../assets/images/egyFlag.png')} /></Box><Box sx={{ paddingLeft: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
                {i18n.resolvedLanguage === 'ar' && <OutlinedInput
                    type="tel"
                     color='dark'
                    value={phoneVal.value}
                    inputProps={{ style: { direction: 'ltr', textAlign: 'left' } }}
                    id="outlined-adornment-mobile"
                    placeholder={t('Enter your mobile number...')}
                    endAdornment={<InputAdornment position="end"><Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}><Box sx={{ borderLeft: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingLeft: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../../assets/images/egyFlag.png')} /></Box><Box sx={{ paddingRight: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
            </FormControl>
            {phoneVal.invalid && !phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Phone is invalid')}</Typography>}
            {phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone is Required')}</Typography>}
            {phoneExist && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone already exist')}</Typography>}
            {authCtx.otpError && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Too many requests')}</Typography>}

        </Box>

        <Button variant='contained' disabled={phoneVal.required || phoneVal.invalid || submitDisabled} color='primary' onClick={onSubmitHandler} fullWidth sx={{ marginBottom: '15px' }}>{t('Verify Mobile Number')}</Button>

    </AuthWrapper>)
};

export default ConfirmPhonePage;