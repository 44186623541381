import { useState, useContext,Fragment } from "react";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckoutContext from "../../store/checkout-context";
import { Card, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { LocationCityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const CitiesSelector = (props) => {
    const navigate = useNavigate();
    const checkoutCtx = useContext(CheckoutContext);
    const { i18n , t } = useTranslation();

    const navigateToLocation = (city) => {
        navigator.geolocation.getCurrentPosition((position) => {
            setTimeout(() => {
                navigate('/location');
            }, 100);
          
          }, (err) => {
            navigate('/location', {
                state: {
                    city: city.name
                }
            })
          });
      
    }

    return (
        <Card>
    <List sx={{ width: '100%' }}>
            {props.cities.map((city, i) => {

                return (
                    <Fragment key={i}>
                        <ListItemButton sx={{py:'16px'}} onClick={navigateToLocation.bind(null, city)} key={i}>
                            <ListItemText primary={city.name} />
                            <img src={ i18n.resolvedLanguage === 'en' ? require('../../assets/images/arrowRightIcon.png'): require('../../assets/images/arrowLeftIcon.png') } alt="" />
                        </ListItemButton>
                      {  props.cities.length > i + 1 && <Divider />}
                    </Fragment>

                );
            })}
        </List>
        </Card>
    
    )
};

export default CitiesSelector;