import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Styles from "./CategoriesCarousel.module.css";
import { Typography, Box } from "@mui/material";
import { getTranslation } from "../../../../utils/helper";
import i18n from "../../../../config/i18n";
import config from "../../../../config/config";
import NoImage from '../../../../assets/images/no-image.jpg';
import { useContext } from "react";
import AuthContext from "../../../../store/auth-context";
import { useTranslation } from "react-i18next";

const CategoriesCarousel = (props) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <Box>
      {props.items.length > 0 && (
        <Swiper
          pagination={{ clickable: true }}
          className={`${Styles.CategoriesCarousel}`}
          spaceBetween={10}
          slidesPerView={"auto"}
        >
          {props.items.map((item, i) => {
            return (
              <SwiperSlide key={i} className={Styles.cat__item}>
                <Link to={`/categories/${item.id}`}>
                
                  {item.image && <img src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.name} className={Styles["cat__item--img"]} />}
                  {!item.image && <img src={NoImage} alt={item.name} className={Styles["cat__item--img"]} />}
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    fontWeight="500"
                    fontSize={12}
                    lineHeight={1.3}
                    color="#172327"
                    className={Styles["cat__item--title"]}
                  >
                    {getTranslation(item, i18n.resolvedLanguage)['name']}
                  </Typography>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      {
        props.items.length === 0 && <Box sx={{padding:'30px'}}><Typography fontSize="14px" textAlign="center" color="#aaa">{ authCtx.branch?.id ? t('No categories found in this branch') : t('No categories found')  }</Typography></Box>
      }
    </Box>
  );
};

export default CategoriesCarousel;
