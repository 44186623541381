import { Box, Button, Typography } from "@mui/material";
import OrdersEmptyImage from "../../../assets/images/emptyOrders.png";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const OrderError = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const LoginHandler = () => {
        navigate('/login', { state: { to: '/orders' } })
    }

    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 120px)' }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
            <img src={OrdersEmptyImage} alt="" style={{ width: '60%', aspectRatio: 1, objectFit: 'contain', marginBottom: '30px' }} />
            <Typography textAlign="center" fontWeight="bold" color="#172327" variant="h5" component="h5">{t('Oops!')}</Typography>
            <Typography textAlign="center" color="#989898" variant="body1" component="p" sx={{ mb: '30px' }}>{t("It looks like you are not logged in")}</Typography>
            <Button variant="contained" size="large" onClick={LoginHandler}>{t('Login')}</Button>
        </Box>
    </Box>

};

export default OrderError;