const HomeIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 19H5C3.93913 19 2.92172 18.5786 2.17157 17.8284C1.42143 17.0783 1 16.0609 1 15V8.708C0.999986 8.02022 1.17732 7.34405 1.51487 6.74479C1.85242 6.14554 2.33879 5.64345 2.927 5.287L7.927 2.257C8.55211 1.8782 9.26907 1.67792 10 1.67792C10.7309 1.67792 11.4479 1.8782 12.073 2.257L17.073 5.287C17.6611 5.64336 18.1473 6.14529 18.4849 6.74436C18.8224 7.34342 18.9998 8.01939 19 8.707V15C19 16.0609 18.5786 17.0783 17.8284 17.8284C17.0783 18.5786 16.0609 19 15 19H13M7 19V15C7 14.2044 7.31607 13.4413 7.87868 12.8787C8.44129 12.3161 9.20435 12 10 12V12C10.7956 12 11.5587 12.3161 12.1213 12.8787C12.6839 13.4413 13 14.2044 13 15V19M7 19H13"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
