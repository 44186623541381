import HomeIcon from "../components/Icons/HomeIcon";
import CartIcon from "../components/Icons/CartIcon";
import OrderingIcon from "../components/Icons/OrderingIcon";
import PersonIcon from "../components/Icons/PersonIcon";

export const bottomTabsPages = [
    {
      label: 'Home',
      route: '/products',
      icon: HomeIcon,
      hasBadge: false
    },
    {
      label: 'Cart',
      route: '/cart',
      icon: CartIcon,
      hasBadge: true
    },
    {
      label: 'Orders',
      route: '/orders',
      icon: OrderingIcon,
      hasBadge: false
    },
    {
      label: 'Account',
      route: '/account',
      icon: PersonIcon,
      hasBadge: false
    },
  ];
  