import { Button, Typography, Box, Divider, FormControl, InputLabel, Select, MenuItem, Alert, TextField, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import axiosConfig from "../../../../config/axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/i18n";
import CheckoutContext from "../../../../store/checkout-context";
import CitiesSelector from "../../../../components/CitiesSelector/CitiesSelector";
import AuthContext from "../../../../store/auth-context";
import {useBranchCities} from "../../../../hooks/useBranchCities";
import SuspenseLoader from "../../../../components/SuspenseLoader/SuspenseLoader";

const DeliveryOption = () => {
    const authCtx = useContext(AuthContext);
    const checkoutCtx = useContext(CheckoutContext);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(authCtx.branch?.city);
    const [selectedBranch, setSelectedBranch] = useState(authCtx.branch);
    const [selectedBranchId, setSelectedBranchId] = useState(authCtx.branch?.id);
    const [address, setAddress] = useState(checkoutCtx.deliverAddress?.address);
    const [cityBranches, setCityBranches] = useState([]);
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [isSafari, setIsSafari] = useState();
    const [locationEnabled,setLocationEnabled] = useState(false);
    const [ isAndroidDevice, setIsAndroidDevice ] = useState(false);
    const branchCitiesQuery = useBranchCities();

    console.log(!branchCitiesQuery.isLoading && branchCitiesQuery.data);

    useEffect(() => {
        if (cityBranches.length === 1) {
            setSelectedBranch(cityBranches[0]);
            setSelectedBranchId(cityBranches[0].id);

        };
    }, [cityBranches])


    const enableLocationHandler = ()=>{
        navigator.geolocation.getCurrentPosition((position) => {
            setLocationEnabled(true);
        }, (err) => {
            setLocationEnabled(false);
        });
    }

    useEffect(() => {
        const android = /(android)/i.test(navigator.userAgent);
        // console.log(android);
        if(android){
            setIsAndroidDevice(true);
        };
        // enableLocationHandler();
      }, []);
    



    useEffect(() => {

        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (!(ua.indexOf('chrome') > -1)) {
                setIsSafari(true);
            };
        }

    }, []);




    return (<Box>

        {checkoutCtx.deliverAddress?.address && <Box sx={{ width: '80%', my: '30px', mx: 'auto' }}>
            <Typography color="#172327" textAlign="center" >{t('Current Address')}</Typography>
            <Typography color="#aaa" fontSize="12px" textAlign="center" sx={{ mx: 'auto' }}>{checkoutCtx.deliverAddress?.address}</Typography>
        </Box>}

        {checkoutCtx.deliverAddress?.address && <>
            <Box sx={{ height: '0' }} />
            <div className="or_separator">
                <Typography component="span" >{t('Or')}</Typography>
            </div>
        </>}
        <Typography sx={{ fontWeight: 'bold', fontSize: 18, lineHeight: 1.2, textAlign: 'center', marginBottom: '15px', color: '#555' }}>{t('Choose your location')}</Typography>
       
      { !branchCitiesQuery.isLoading && branchCitiesQuery.data?.length > 0  && <CitiesSelector cities={branchCitiesQuery.data} /> }
     
      {branchCitiesQuery.isLoading && <SuspenseLoader />}


    </Box>
    );
};

export default DeliveryOption;