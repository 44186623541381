import Styles from './SectionTitle.module.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const SectionTitle = (props)=>{
    const { t } = useTranslation();
    return (
        <Box className={`${Styles.SectionTile}`} sx={{padding:'16px'}}>
            <Typography fontWeight="bold" variant="subtitle1" component="h6" className={Styles.SectionTile__title}>{t(props.title)}</Typography>
            <Button color='inherit' className={Styles.SectionTile__button} onClick={props.onclick}>{t(props.actionLabel)}</Button>
        </Box>
    );
};

export default SectionTitle;