import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography, Box, Grid, useTheme } from "@mui/material";
import { getTranslation, excerpt } from "../../../../utils/helper";
import i18n from "../../../../config/i18n";
import config from "../../../../config/config";
import NoImage from '../../../../assets/images/no-image.jpg';
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../store/auth-context";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PlusIcon from "../../../../components/Icons/PlusIcon";
import { ThemeOptionsContext } from "../../../../theme/ThemeProvider";
import { globalSetting } from "../../../../constants/settings";


const ProductsCarousel = (props) => {
    const authCtx = useContext(AuthContext);
    const appearanceCtx = useContext(ThemeOptionsContext);
    const [basePrice, setBasePrice] = useState();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (props.item?.type === 'simple') {
            setBasePrice(parseInt(props.item?.item_option[0]?.price));
        };
    }, []);

    const simplePriceBase = (options) => {
        return parseInt(options[0]?.price);
    }

    const variablePriceBase = (options) => {
        let prices = options.map(item => parseInt(item?.price));
        return Math.min(...prices)+' - '+Math.max(...prices);
    }

    return (
        <Box>
            {(props.items && props.items?.length > 0) && (
                <Swiper
                    style={{ paddingLeft: 15, paddingRight: 15 }}
                    pagination={{ clickable: true }}
                    spaceBetween={10}
                    slidesPerView={globalSetting.home.recommend_products.cols || "auto"}             
                >
                    {props.items.map((item, i) => {
                        return (
                            <SwiperSlide key={i} style={{ maxWidth: '300px' }}>

                                <Card component={Link} to={`/products/${item.id}`} sx={{ display: 'block', marginBottom: 5, color: 'inherit', textDecoration: 'none', position: 'relative', boxShadow: '0px 1px 5px #0005' }}>

                                    {item.image && <CardMedia sx={{ height: 140, borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }} image={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} title={item.name} />}
                                    {item.category_img && <CardMedia sx={{ height: 140, borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }} image={item.image.includes('demo') ? config.url + '/' + item.category_img : config.assets + '/' + item.category_img} title={item.name} />}
                                    {!item.image && <CardMedia sx={{ height: 140, }} image={NoImage} title={item.name} />}

                                    <CardContent>
                                        <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-between' }}>
                                            <Typography gutterBottom variant="h6"  fontSize={16} component="div">
                                                {getTranslation(item, i18n.resolvedLanguage)['name']}
                                            </Typography>

                                            <Box>
                                                {item.type === 'variable' && <Typography
                                                    variant="body1"
                                                    component="p"
                                                    fontSize={16}
                                                    color="primary"
                                                    fontWeight="bold"
                                                    sx={{ margin: 0 }}
                                                    lineHeight={1}
                                                >
                                                  {variablePriceBase(item?.item_option)} {t('EGP')}
                                                </Typography>}
                                                {item.type === 'simple' && <Typography
                                                    variant="body1"
                                                    component="p"
                                                    fontSize={16}
                                                    color="primary"
                                                    fontWeight="bold"
                                                    sx={{ margin: 0 }}
                                                    lineHeight={1}
                                                >
                                                    {simplePriceBase(item?.item_option)} {t('EGP')}
                                                </Typography>}
                                            </Box>
                                        </Box>

                                        { globalSetting.home.recommend_products.show_description && item.desc && <Typography sx={{ minHeight: '50px' }} variant="body2" color="text.secondary">
                                            {excerpt(item.desc, 100)}
                                        </Typography>}
                                        <Box sx={{ right: '8px', position: 'absolute', bottom: 5 }}>
                                            <div style={{ transform: i18n.resolvedLanguage === 'ar' ? 'scaleX(-1)' : 'none' }}>
                                                <PlusIcon color={theme.palette.primary.main} />
                                            </div>
                                        </Box>

                                    </CardContent>

                                </Card>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}

            {
                props.items.length === 0 && <Box sx={{ padding: '30px' }}><Typography fontSize="14px" textAlign="center" color="#aaa">{authCtx.branch?.id ? t('No categories found in this branch') : t('No categories found')}</Typography></Box>
            }
        </Box>
    );
};

export default ProductsCarousel;
