import {  Stepper, Step, StepLabel, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const steps = [
    'Mobile',
    'Verification',
    'Confirm',
];

const CheckoutStepper = (props)=>{
    const { t } = useTranslation();
    return (
        <Stepper activeStep={props.step} alternativeLabel sx={{my:'16px'}}>
        {steps.map((label) => (
            <Step key={label} >
                <StepLabel><Box sx={{transform:'translateY(-7px)'}}>{t(label)}</Box></StepLabel>
            </Step>
        ))}
    </Stepper>
    );
};

export default CheckoutStepper;