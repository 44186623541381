import { Box, Typography, Paper, Grid, Button, useTheme, ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductItemImage from '../../../../assets/images/productfullimage.png';
import CartTrashIcon from '../../../../components/Icons/CartTrashIcon';
import { getTranslation } from '../../../../utils/helper';


const OrderItem = ({item}) => {
    const theme = useTheme();
   const { i18n, t } = useTranslation();

    return <Paper elevation={1} sx={{ mb: '16px', p: '16px', position: 'relative', overflow: 'hidden' }}>
        <Grid container alignItems="center">
            {/* <Grid item xs={3}>
                <img src={ProductItemImage} style={{ maxWidth: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: '50%' }} />
            </Grid> */}
            <Grid item xs={12} sx={{ pl: '16px' }}>
                <Box>
                    <Grid container sx={{ mb: '0' }}>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1" component="h6" lineHeight={1.2} fontWeight="bold" >{getTranslation(item,i18n.resolvedLanguage)['parent']}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography textAlign="right" color={theme.palette.primary.main} fontWeight="bold">{item?.subtotal} {t('EGP')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                           {  <Typography variant='body2'>{t('Option')}: {getTranslation(item,i18n.resolvedLanguage)['name'] || t('Regular')}</Typography>}
                           { item.extras?.length > 0 && <Typography variant='body2' noWrap>{t('Extras')}: {
                            item?.extras?.map((extra,i)=>{
                                return <span>{ getTranslation(extra,i18n.resolvedLanguage).name}{i < item?.extras.length - 1 ? ', ':'' }</span>
                            })
                           }</Typography>}
                           { item.note && <Typography variant='body2' noWrap>{t('Note')}: {item.note}</Typography>}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    </Paper>
}

const ItemsList = (props) => {
    return (<>
        {props?.items?.length > 0 && <Box sx={{ p: '16px', mb: 'auto', height: '100%', overflow: 'auto' }}>
            {
                props?.items?.map((item, i) => {
                    return <OrderItem key={i} item={item} />
                })
            }
        </Box>
        }
    </>
    )
};

export default ItemsList;