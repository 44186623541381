import { createContext, useContext, useState } from "react";
import axiosInstance from '../config/axios';
import { useNavigate } from "react-router-dom";
import AuthContext from "./auth-context";

const RegisterationContext = createContext({
    token: undefined,
    access_token: undefined,
    phone: '',
    storeToken: (token) => { },
    setAccessToken: (token) => { },
    register: async (data, token) => { },
    accountExists: (phone) => { },
});


export const RegisterationProvider = (props) => {
    const [token, setToken] = useState();
    const [phone, setPhone] = useState();
    const [accessToken, setAccessToken] = useState();
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const regsiterCtx = {
        token,
        phone,
        access_token: accessToken,
        setAccessToken: (token) => {
            setAccessToken(token);
        },
        storeToken: (token) => {
            setToken(token);
            sessionStorage.setItem('gromenu_auth', token);
        },
        accountExists: async (phone) => {
            // console.log('check',phone);
            try {
                const phoneCheckRes = await axiosInstance.post(`/customer/phone-check`, {
                    'phone': '0' + phone
                });

                if (phoneCheckRes.data.valid === false) {
                    // console.log('phone not exist');
                    setPhone(phone);
                };

                if (phoneCheckRes.data.valid === true) {
                    // console.log('phone exist');
                    setPhone(phone);
                }

                return phoneCheckRes.data;

            } catch (error) {
                console.log(error.response);
                console.log('error phoneCheckRes', error);
            }
        },
        register: async (data) => {
            try {
                //  console.log('register data',data);
                const registerRes = await axiosInstance.post(`/customer/register`, {
                    name: data.name,
                    password: data.password,
                    phone: data.phone
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                return new Promise((resolve, reject) => {
                    resolve(registerRes.data);
                    reject(null);
                });
            } catch (error) {
                console.log('sdasdsdasd', error)
            }
        },
    }

    return <RegisterationContext.Provider value={regsiterCtx}>{props.children}</RegisterationContext.Provider>

};

export default RegisterationContext;

