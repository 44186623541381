import { Box, Typography, ButtonBase, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import CustomerContext from "../../store/customer-context";
import Avatar from "../../assets/images/avatar.png";
import config from "../../config/config";
import { useTranslation } from "react-i18next";
import InfoContext from "../../store/info-context";
import AuthContext from "../../store/auth-context";


const NonAuthAvatar = () => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  return <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      py: "50px",
    }}
  >
    <>
      <Box sx={{ position: "relative", marginBottom: "5px" }}>
        {info?.logo && (
          <img
            alt=""
            src={`${config.assets}/${info?.logo}`}
            style={{
              width: "93px",
              maxWidth: "100%",
              aspectRatio: 1,
              objectFit: "cover",
              borderRadius: "150px",
            }}
          />
        )}
        {!info?.logo && (
          <img
            alt=""
            src={Avatar}
            style={{
              width: "93px",
              maxWidth: "100%",
              aspectRatio: 1,
              objectFit: "cover",
              borderRadius: "150px",
            }}
          />
        )}

      </Box>
      <Box sx={{ maxWidth: '50%', marginX: 'auto' }}>
        <Typography fontSize={20} fontWeight="bold" sx={{ mb: "10px", textAlign: 'center' }}>
          {t('Welcome to')} {info?.name && <><br />{info?.name} </>}
        </Typography>

        <Button variant="contained" component={Link} to="/login" fullWidth>
          {t("Login")}
        </Button>
      </Box>


    </>
  </Box>
}

const AuthAvatar = ({ edit }) => {
  const { info } = useContext(CustomerContext);
  const infoCtx = useContext(InfoContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const openUploadDialogHandler = () => {
    setDialogOpen(true);
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: "50px",
      }}
    >
      <>
        <Box sx={{ position: "relative", marginBottom: "10px" }}>
          {infoCtx.info?.logo && (
            <img
              alt=""
              src={`${config.assets}/${infoCtx.info?.logo}`}
              style={{
                width: "93px",
                maxWidth: "100%",
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: "150px",
              }}
            />
          )}
          {!infoCtx.info?.logo && (
            <img
              alt=""
              src={Avatar}
              style={{
                width: "93px",
                maxWidth: "100%",
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: "150px",
              }}
            />
          )}
        </Box>
        <Typography fontSize={20} fontWeight="bold" sx={{ mb: "5px" }}>
          {info?.name}
        </Typography>
        {!edit && (
          <Link to="/profile" style={{ color: "inherit" }}>
            <Typography fontSize={17} color="#172327" sx={{ mb: "10px" }}>
              {t("View Profile")}
            </Typography>
          </Link>
        )}
      </>
    </Box>
  );
}

const UserAvatar = ({ edit }) => {
  const authCtx = useContext(AuthContext);

  return <>
    {authCtx.isLoggedIn && <AuthAvatar edit={edit} />}
    {!authCtx.isLoggedIn && <NonAuthAvatar />}
  </>

};

export default UserAvatar;
