import { useState, createContext, useEffect, useContext } from "react";
import axiosInstance from "../config/axios";
import AuthContext from "./auth-context";

const CustomerContext = createContext({
    info: null,
    fetch: () => { },
    update: (data) => { }
});

export const CustomerProvider = (props) => {
    const authCtx = useContext(AuthContext);
    const [customerInfo, setCustomerInfo] = useState();

    const fetchCustomer = async () => {

        if (authCtx.token) {
            try {
                const customerRes = await axiosInstance.get(`/customer/info`, { headers: { 'Authorization': `Bearer ${authCtx.token}` } });
                setCustomerInfo(customerRes.data.customer);
            } catch (error) {
                console.log('customer error', error);
            }
        }
    };

    const updateCustomer = async (data) => {

        try {
            const profileUpdateRes = await axiosInstance.post(`/customer/info/update`, data);
            setCustomerInfo(profileUpdateRes.data.customer);
        } catch (error) {

        }
    }



    useEffect(() => {

        fetchCustomer();

    }, [authCtx.token]);

    const customerCtx = {
        info: customerInfo,
        fetch: fetchCustomer,
        update: updateCustomer
    }
    return <CustomerContext.Provider value={customerCtx}>{props.children}</CustomerContext.Provider>
};

export default CustomerContext;