import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({
  baseURL: `${config.url}/api`,
});

if (localStorage.getItem('rdx_token')) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('rdx_token')}`;
};

export default axiosInstance;
