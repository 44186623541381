import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import emptyImage from '../../assets/images/emptyOrders.png';
const ClosedPage = ()=>{
    const { t } = useTranslation();
    return <Box sx={{textAlign:'center',position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}} className="closed">
        <img src={emptyImage} alt="" style={{marginBottom:'30px'}} />
        <Typography variant="h3">{t('Oops!')}</Typography>
        <Typography>{t('The site is not available now')}</Typography>
    </Box>
};

export default ClosedPage;

