import React, { useState, useContext } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Typography, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Box, AppBar, Toolbar } from "@mui/material";
import axiosConfig from "../../../config/axios";
import AuthContext from "../../../store/auth-context";
import { useTranslation } from "react-i18next";
import CheckoutContext from "../../../store/checkout-context";
import InfoContext from "../../../store/info-context";
import AuthWrapper from "../../../components/AuthWrapper/AuthWrapper";

const LoginPage = () => {
    const { state } = useLocation();
    const [phoneVal, setPhoneVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordVal, setPasswordVal] = useState();
    const [loginError, setLoginError] = useState();
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const checkoutCtx = useContext(CheckoutContext);
    const infoCtx = useContext(InfoContext);



    const onPhoneChange = (e) => {
        let val = e.target.value;
        val = val.replace(/^0+/, '');
        setPhoneVal(val);
    }

    const onPasswordChange = (e) => {
        setPasswordVal(e.target.value);
    }

    console.log(checkoutCtx.pendingCheckout);

    const loginHandler = async () => {
        try {
            const loginRes = await axiosConfig.post(`/customer/login`, { phone: 0 + phoneVal, password: passwordVal });
            authCtx.login(loginRes.data?.token);
            setLoginError(undefined);
          
            if (checkoutCtx.pendingCheckout) {
                navigate('/checkout-confirm');
            };
            if (!checkoutCtx.deliverAddress?.address) {
                navigate('/delivery', { state: { to: `/${state.to}` } });
            };
        } catch (error) {
            if (!error?.response) {
            } else if (error.response?.status === 422) {
                setLoginError("Missing Phone or Password");
            } else if (error.response?.status === 401) {
                setLoginError("Wrong Phone or Password");
            } else {
                console.log("Login Failed", error.response?.status);
            }

        }


    }

    const signUpHandler = () => {
        navigate('/confirm-phone');

    }

    const forgotPasswordHandler = (e) => {
        e.preventDefault();
        navigate('/forgot-password');
    }

    return (
        <AuthWrapper hideWelcomeMessage={false}>
            <Box>
                <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '30px' }}>{t('Login to your account')}</Typography>
                {/* <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t('Login to your account')}</Typography> */}
                <FormControl fullWidth variant="outlined" color="dark">
                    <InputLabel htmlFor="outlined-adornment-mobile" color="dark">{t('Mobile')}</InputLabel>
                    <OutlinedInput
                        autoComplete="username"
                        color="dark"
                        id="outlined-adornment-mobile"
                        type="tel"
                        data-cy="tel-input"
                        inputProps={{ style: { textAlign: 'left' } }}
                        startAdornment={i18n.resolvedLanguage === 'en' && <InputAdornment position="start">+20</InputAdornment>}
                        endAdornment={i18n.resolvedLanguage === 'ar' && <InputAdornment position="end">20+</InputAdornment>}
                        label={t("Mobile")}
                        onChange={onPhoneChange}
                        value={phoneVal}
                    />
                </FormControl>
            </Box>
            <Box sx={{ mt: '20px' }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password" color="dark">{t('Password')}</InputLabel>
                    <OutlinedInput
                        autoComplete="password"
                        color="dark"
                        inputProps={{ style: { textAlign: 'left' } }}
                        id="outlined-adornment-password"
                        data-cy="password-input"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword((prev) => !prev)}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t("Password")}
                        onChange={onPasswordChange}
                    />
                </FormControl>
            </Box>
            <Typography sx={{ padding: '5px 0' }} textAlign="end" color="#5E9460" fontSize={12}><a href="/" onClick={forgotPasswordHandler} style={{ color: 'inherit' }}>{t('Forgot Password?')}</a></Typography>

            {loginError && <Typography data-cy="auth-error-message" sx={{ padding: '5px 0 0' }} textAlign="center" color="#f00" fontSize={14}>{t(loginError)}</Typography>}
            <Box sx={{ mt: '20px' }}>
                <Button data-cy="login-button" disableElevation sx={{ width: '100%' }} size="large" variant="contained" onClick={loginHandler}>{t('Login')}</Button>
            </Box>
            {infoCtx.info?.account_active === true && <Box sx={{ mt: '20px' }}>
                <Button data-cy="signup-button" disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={signUpHandler}>{t('Sign Up')}</Button>
            </Box>
            }
        </AuthWrapper>
    )
};

export default LoginPage;