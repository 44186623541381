import { Typography } from "@mui/material";
import styled from "styled-components";

const StyledDiv = styled.div`
    width:100%;
    height:100%;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
`; 


const Error = ({ title, subtitle, children }) => {
    
    return <StyledDiv>
        <Typography component={"h2"} variant="h2" >{title}</Typography>
        <Typography>{subtitle}</Typography>
        {children ? children : null}
    </StyledDiv>
};;
export default Error;