import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { InfoContextProvider } from './store/info-context';
import { CheckoutProvider } from './store/checkout-context';
import { CartContextProvider } from './store/cart-context';
import { AuthProvider } from './store/auth-context';
import ThemeContextProvider, { AppThemeOptionsProvider } from './theme/ThemeProvider';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { useTranslation } from 'react-i18next';
import { CustomerProvider } from './store/customer-context';
import config from './config/config';
import { RegisterationProvider } from './store/register-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient();

const Main = () => {
  const { i18n } = useTranslation();
  const cacheRtl = createCache({
    key: i18n.resolvedLanguage === 'ar' ? 'muirtl' : 'muiltr',
    stylisPlugins: i18n.resolvedLanguage === 'ar' ? [prefixer, rtlPlugin] : [],
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <BrowserRouter basename={config.developmentMode ? '/' : '/menu'}>
        <CacheProvider value={cacheRtl}>
          <ThemeContextProvider>
            <AppThemeOptionsProvider>
              <RegisterationProvider>
                <AuthProvider>
                  <InfoContextProvider>
                    <CheckoutProvider>
                      <CartContextProvider>
                        <CustomerProvider>
                          <App />
                        </CustomerProvider>
                      </CartContextProvider>
                    </CheckoutProvider>
                  </InfoContextProvider>
                </AuthProvider>
              </RegisterationProvider>
            </AppThemeOptionsProvider>
          </ThemeContextProvider>
        </CacheProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )

}



const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(<Main />);


