export const globalSetting = {
    password: {
        criteria: {
            minSymbols: 0,
            minUppercase: 0,
            minLength: 6,
            minNumbers: 1
        },
        criteriaText: 'Password must be at least 6 characters containing a number'
    },
    home: {
        categories: {
            cols: 4,
            rounded: false,
            count:12
        },
        recommend_products: {
            cols: 2,
            show_description: false
        }

    }
}