import React, { useState } from "react";

import { useNavigate, Link } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Typography, FormControl, InputLabel, OutlinedInput,  InputAdornment, Box } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import CheckoutStepper from "../../components/CheckoutStepper/CheckoutStepper";


const CheckoutRegisterPage = () => {
    const [phoneVal, setPhoneVal] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const [passwordVal, setPasswordVal] = useState();
    const navigate = useNavigate();

    const onPhoneChange = (value) => {
        setPhoneVal(value);
    }

    const onPasswordChange = (value) => {
        setPasswordVal(value);
    }


    return (
        <Box id="CheckoutRegisterPage">
            <CustomAppBar hasBack backDark notif title="Chechout" />
            <Box sx={{ p: '16px' }}>
              <CheckoutStepper step={1} />
                <Box>
                    <Typography variant="h6" color="#000" sx={{ mb: '5px' }} component="h2">Confirm phone number</Typography>
                    <Typography variant="body1" color="#07485B" sx={{ mb: '30px' }} component="h2">Get a verification code sent to your phone NUM.</Typography>
                    <FormControl fullWidth variant="outlined" sx={{ mb: '16px' }}>
                        <InputLabel htmlFor="outlined-adornment-mobile">Full Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-mobile"
                            label="Full Name"
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-mobile">Mobile</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-mobile"
                            startAdornment={<InputAdornment position="start">+2</InputAdornment>}
                            label="Mobile"
                        />
                    </FormControl>
                </Box>


                <Box sx={{ mt: '20px' }}>
                    <Button disableElevation sx={{ width: '100%',mb:'16px' }} size="large" variant="contained" onClick={() => navigate('/checkout-verify')}>send the code</Button>
                    <Button disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
                </Box>
            </Box>
        </Box>
    )
};

export default CheckoutRegisterPage;