import Styles from './style.module.css';


const CategoriesBarSkeleton = () => {

    const skeletonData = Array.from(Array(6).keys());


    return <div className={Styles['CategoriesBar-skeleton']}>
   
        { skeletonData.map((item, i) => {
                return (<div key={i} className={`${Styles['CategoriesBar-skeleton__item']} skeleton`}></div>)
            })
        }
  
    </div>
}



export default CategoriesBarSkeleton;