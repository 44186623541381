import { useState } from 'react';
import { Box, Typography, Paper, Grid, Button, useTheme, ButtonBase, Card } from '@mui/material';
import ProductItemImage from '../../../../assets/images/no-image.jpg';
import CartTrashIcon from '../../../../components/Icons/CartTrashIcon';
import config from '../../../../config/config';
import CartContext from '../../../../store/cart-context';
import { useContext } from 'react';
import IncIcon from '../../../../components/Icons/IncreaseIcon';
import DecreaseIcon from '../../../../components/Icons/DecreaseIcon';
import { useTranslation } from 'react-i18next';
import { ThemeOptionsContext } from '../../../../theme/ThemeProvider';

const CartItem = ({item}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const cartCtx = useContext(CartContext);
    const [qty, setQty] = useState(item.qty);
    const appearanceCtx = useContext(ThemeOptionsContext);

    const qtyIncHandler = () => {
        setQty(prev => {
            cartCtx.update({
                cid: item.cid,
                id: item.id,
                qty: prev + 1,
                price: item.price
            })
            return prev + 1;
        })
    }

    const qtyDecHandler = () => {
        setQty(prev => {
            if (prev <= 1) {
                cartCtx.update({
                    cid: item.cid,
                    id: item.id,
                    qty: 1,
                    price: item.price
                })
                return 1
            } else {
                cartCtx.update({
                    cid: item.cid,
                    id: item.id,
                    qty: prev - 1,
                    price: item.price
                })
                return prev - 1;
            }

        })
    }

    return <Card elevation={0} sx={{ mb: '16px', p: '16px', position: 'relative', overflow: 'hidden' }}>
        <Grid container>
            {appearanceCtx.appearance.menu_view !== 'without_img' && <Grid item xs={3}>

                {item.img && <img src={item.img.includes('demo') ? config.url + '/' + item.img : config.assets + '/' + item.img} style={{ maxWidth: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: '50%' }} alt='' />}
                {!item.img && <img src={ProductItemImage} style={{ maxWidth: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: '50%' }} alt='' />}


            </Grid>}
            <Grid item xs={appearanceCtx.appearance.menu_view !== 'without_img' ? 9 : 12} sx={{ pl: '16px' }}>
                <Box>
                    <Grid container sx={{ mb: '10px' }}>
                        <Grid item xs={9}>
                            <Typography variant="subtitle1" component="h6" lineHeight={1.2} fontWeight="bold" >{item.name}</Typography>
                        </Grid>

                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ marginBottom: '5px' }}>{item.size?.label}</Typography>
                            <Box style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                {item?.extras?.length > 0 && item.extras.map((extra, i) => {
                                    return <Typography style={{ display: 'inline-flex' }}><Typography variant="body2" key={i}>{`${extra?.extra_name}`}</Typography>{(extra.qty && extra.qty > 1) && <Typography> (x{extra.qty})  </Typography>} <Typography>{item?.extras?.length > i + 1 && ', '}</Typography> </Typography>
                                })}
                                {item?.generalExtras?.length > 0 && <span>|</span>}
                                {item?.generalExtras?.length > 0 && item?.generalExtras.map((extra, i) => {
                                    return <Typography variant="body2" style={{ display: 'inline' }} key={i}>{`${extra?.extra_name}`} {item?.generalExtras?.length > i + 1 && ', '}</Typography>
                                })}
                            </Box>
                            {item.note && <Typography variant='body2' noWrap>{t('Notes')}: {item.note}</Typography>}
                            <Grid container alignItems="center" sx={{ mt: '10px', }} >

                                <Grid item xs={6}>
                                    <Typography textAlign="left" color={theme.palette.primary.main} fontWeight="bold">{(item.price * item.qty)} {t('EGP')}</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex' }} >
                                    <Box sx={{ display: 'flex', flexDirection: i18n.resolvedLanguage === 'en' ? 'row' : 'row-reverse', alignItems: 'center', ml: 'auto' }} >
                                        <ButtonBase onClick={qtyDecHandler} disabled={qty === 1} ><DecreaseIcon color={theme.palette.primary.main} /></ButtonBase>
                                        <Box sx={{ px: '15px' }}><Typography sx={{ textAlign: 'center' }}>{qty}</Typography></Box>
                                        <ButtonBase onClick={qtyIncHandler}><IncIcon color={theme.palette.primary.main} /></ButtonBase>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>

                </Box>
                <ButtonBase sx={{ position: 'absolute', top: 0, right: 0, borderRadius: '0 0 15px 0' }} onClick={() => { cartCtx.remove(item.cid) }}><CartTrashIcon color="#fc0" /></ButtonBase>
            </Grid>
        </Grid>
    </Card>
}

const CartList = ({items}) => {
    return (<><Box sx={{ p: '16px', mb: 'auto', height: '100%', overflow: 'auto', width: '100%', backgroundColor: 'var(--backgroundColor)' }}>
        {items.map((item, i) => {
            return <CartItem key={i} item={item} />
        })}
    </Box>

    </>
    )
};

export default CartList;