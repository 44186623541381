
import axiosInstance from "../config/axios";

export const fetchCategories = async (auth, i18n) => {
    let catsRes = {};
    try {
        if (auth.branch?.id) {
            catsRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/${auth.branch?.id}/categories`);
        } else {
            catsRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/categories`);
        };

        return catsRes?.data;

    } catch (error) {
        console.log(error);
    }
};

export const fetchCategory = async (categoryId, auth, i18n) => {
    let productsRes = {};
    if (categoryId) {
        try {
            if (auth?.branch?.id) {
                productsRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/${auth?.branch?.id}/category/${categoryId}`);
            } else {
                productsRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/category/${categoryId}`);
            };
            return productsRes?.data;
        } catch (error) {
            console.log('fetchCategory', error);
        }
    };
    }