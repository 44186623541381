import Styles from './ProductsList.module.css';
import ProductItem from "../ProductItem/ProductItem"

const ProductsList = (props) => {

    return (
        <div className={`${Styles.ProductList} px-16`} >
          
                {props.items?.map((product, i) => {
                    return (<ProductItem key={i} item={product} />)
                })}
        
        </div>
    )
};
export default ProductsList;


