import { Box, Typography, Button, Stepper, Step, StepLabel, Grid, useTheme } from '@mui/material';
import CustomAppBar from '../../components/CustomAppBar/CustomAppBar';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useOrder from '../../hooks/useOrder';

const TrackOrderPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const theme = useTheme();
    const { data, isLoading, isError } = useOrder(id);

    return (<Box id="TrackOrderPage">
        <CustomAppBar title={t("Tracking")} hasBack notif backDark />
        <Box sx={{ px: '16px', py: '20px' }}>
            <Box sx={{ border: '1px solid rgba(66, 102, 104, 0.1)', p: '16px', borderRadius: '10px', mb: '16px' }}>
                <Box sx={{ mb: '16px' }}>
                    <Typography color="#172327" fontWeight="bold" variant="body2">{t('Order number')} :  {data?.inv_num}</Typography>
                </Box>
                <Box sx={{ mb: '16px' }}>
                    <Typography color="#172327" variant="body2">{t('Time and Date')} : {moment(new Date(data?.created_at)).format('DD-MM-YYYY,  hh:mm A')}</Typography>
                </Box>
                {data?.branch?.phone && <Box>
                    <Button variant='outlined' component="a" style={{ color: '#172327' }} href={`tel:${data?.branch?.phone}`}>{t('Contact Us')}</Button>
                </Box>}
            </Box>
            {data?.status !== 'cancelled' && <Box sx={{ border: '1px solid rgba(66, 102, 104, 0.1)', p: '16px', borderRadius: '10px', mb: '16px' }}>
                <Stepper orientation="vertical">
                    <Step active={data?.status === 'pending' || data?.status === 'preparing' || data?.status === 'ready' || data?.status === 'out for delivery' || data?.status === 'completed'}>
                        <StepLabel
                            color="info"
                            optional={<Typography variant="caption" >{t('Process your order')}</Typography>}
                            icon={<Box sx={{ backgroundColor: (data?.status === 'pending' || data?.status === 'preparing' || data?.status === 'ready' || data?.status === 'out for delivery' || data?.status === 'completed') ? theme.palette.primary.main : "#ccc", borderRadius: '50px', aspectRatio: 1, p: '5px', height: '25px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={require(`../../assets/images/clockLight.png`)} style={{ aspectRatio: 1, maxWidth: '100%', height: 'auto' }} />
                            </Box>}
                        >
                            <Typography variant="body1" fontWeight="bold" >{t('Pending')}</Typography>
                        </StepLabel>
                    </Step>

                    <Step active={data?.status === 'preparing' || data?.status === 'ready' || data?.status === 'Out for delivery' || data?.status === 'completed'}>
                        <StepLabel
                            color="info"
                            optional={<Typography variant="caption" >{t('Your order is getting ready')}</Typography>}
                            icon={<Box sx={{ backgroundColor: (data?.status === 'preparing' || data?.status === 'ready' || data?.status === 'out_for_delivery' || data?.status === 'completed') ? theme.palette.primary.main : "#ccc", borderRadius: '50px', aspectRatio: 1, p: '5px', height: '25px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={require(`../../assets/images/preparingIcon.png`)} style={{ aspectRatio: 1, maxWidth: '100%', height: 'auto' }} />
                            </Box>}
                        >
                            <Typography variant="body1" fontWeight="bold" >{t('preparing')}</Typography>
                        </StepLabel>
                    </Step>

                    <Step active={data?.status === 'ready' || data?.status === 'out for delivery' || data?.status === 'completed'}>
                        <StepLabel
                            color="info"
                            optional={<Typography variant="caption" >{t('Your order is ready')}</Typography>}
                            icon={<Box sx={{ backgroundColor: (data?.status === 'ready' || data?.status === 'out for delivery' || data?.status === 'completed') ? theme.palette.primary.main : "#ccc", borderRadius: '50px', aspectRatio: 1, p: '5px', height: '25px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={require(`../../assets/images/readyIcontrack.png`)} style={{ aspectRatio: 1, maxWidth: '100%', height: 'auto', objectFit: 'contain' }} />
                            </Box>}
                        >
                            <Typography variant="body1" fontWeight="bold" >{t('ready')}</Typography>
                        </StepLabel>
                    </Step>

                    <Step active={data?.status === 'out for delivery' || data?.status === 'completed'}>
                        <StepLabel
                            color="info"
                            optional={<Typography variant="caption" >{t('Your order is already on the way')}</Typography>}
                            icon={<Box sx={{ backgroundColor: (data?.status === 'out for delivery' || data?.status === 'completed') ? theme.palette.primary.main : "#ccc", borderRadius: '50px', aspectRatio: 1, p: '5px', height: '25px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={require(`../../assets/images/outDelivery.png`)} style={{ aspectRatio: 1, maxWidth: '100%', height: 'auto' }} />
                            </Box>}
                        >
                            <Typography variant="body1" fontWeight="bold" >{t('Out For Delivery')}</Typography>
                        </StepLabel>
                    </Step>

                    <Step active={data?.status === 'completed'}>
                        <StepLabel
                            color="info"
                            optional={<Typography variant="caption" >{t('Your order is completed')}</Typography>}
                            icon={<Box sx={{ backgroundColor: (data?.status === 'completed') ? theme.palette.primary.main : "#ccc", borderRadius: '50px', aspectRatio: 1, p: '5px', height: '25px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={require(`../../assets/images/completedIcon.png`)} style={{ aspectRatio: 1, maxWidth: '100%', height: 'auto' }} />
                            </Box>}
                        >
                            <Typography variant="body1" fontWeight="bold" >{t('Completed')}</Typography>
                        </StepLabel>
                    </Step>
                </Stepper>
            </Box>}
            {data?.status === 'cancelled' && <Box sx={{ paddingY: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={require('../../assets/images/cancelledImg.png')} style={{ marginBottom: '30px' }} />
                <Typography textAlign="center" fontSize="22px">{t('Your order is cancelled')}</Typography>
            </Box>}
            <Box>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <Button fullWidth variant="outlined" component={Link} to="/">{t('Back to home')}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" component={Link} to={`/orders/${id}`}>{t('View Order')}</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    </Box>);
};

export default TrackOrderPage;