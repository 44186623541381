import Styles from './style.module.css';
import { Paper, Grid, Box } from '@mui/material';

const ProductItemSkeleton = () => {

    return <Paper className={Styles.ProductItem} elevation={3}>
        <Box component="div">
            <Grid container>
                <Grid item xs={4}>
                    <div className={`${Styles.image} skeleton`}></div>
                </Grid>
                <Grid item xs={8}>
                    <Box className="p-16">
                        <div className={`${Styles.title} skeleton`}></div>
                        <div className={`${Styles.desc}`}>
                            <span className='skeleton'></span>
                            <span className='skeleton'></span>
                            {/* <span className='skeleton'></span> */}
                        </div>
                        <div className={`${Styles.price} skeleton`}></div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Paper>

}

const ProductsListSkeleton = ({ length }) => {
    const skeletonData = Array.from(Array(length?length:6).keys());
    return (
        <Box sx={{ px: '16px' }}>
            {skeletonData.map((item, i) => {
                return <ProductItemSkeleton key={i} />
            })}
        </Box>
    )

}

export default ProductsListSkeleton;