import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { fetchOffers } from "../providers/offers";

const useOffers = (enabled) => {
    const { i18n } = useTranslation();
    const offersQuery = useQuery({
        queryKey:['offers'],
        queryFn:()=>fetchOffers(i18n),
        enabled
    });

    return offersQuery;

}

export default useOffers;