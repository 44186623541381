import { useContext} from "react"
import { useTranslation } from "react-i18next";
import AuthContext from "../store/auth-context";
import { useQuery } from "@tanstack/react-query";
import { fetchOrders } from "../providers/orders";

const useOrders = () => {
    const { i18n } = useTranslation();
    const authCtx = useContext(AuthContext);
    const ordersQuery = useQuery({
        queryKey: ['orders'],
        queryFn: () => fetchOrders(authCtx, i18n)
    });
    return ordersQuery;
}

export default useOrders;