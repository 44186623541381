import Styles from './style.module.css';

export const ProductImageSkeleton = () => {
    return <div className={`${Styles['image-skeleton']} skeleton`}></div>
}

export const ProductTitleSkeleton = () => {
    return <div className={`${Styles['title-skeleton']} skeleton`}></div>
}

export const ProductDescriptionSkeleton = () => {
    return <div className={`${Styles['description-skeleton']}`}>
        <span className='skeleton'></span>
        <span className='skeleton'></span>
        <span className='skeleton'></span>
    </div>
}



