import axiosInstance from "../config/axios";
import i18n from "../config/i18n";

export const fetchInfo = async () => {
    try {
        const infoRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/business/info`);
        return infoRes.data.info;

    } catch (error) {
        console.log('info res error', error);
    }
}

export const fetchLanguages = async () => {
    try {
        const langRes = await axiosInstance.get(`/languages`);
        return langRes.data.languages;

    } catch (error) {
        console.log('info res error', error);
    }

}

export const fetchTax = async () => {
    try {
        const taxRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/tax`);
        return taxRes.data.tax;

    } catch (error) {
        console.log('info res error', error);
    }
}

