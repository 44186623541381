import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Link, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { Typography, useTheme, Box, Badge } from "@mui/material";
import Styles from './BottomTabs.module.css';
import { useTranslation } from "react-i18next";
import CartContext from "../../store/cart-context";
import InfoContext from "../../store/info-context";
import { bottomTabsPages } from "../../constants/navigation";

const BottomTabs = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const cartCtx = useContext(CartContext);
  const infoCtx = useContext(InfoContext);
  return (
    <>    <Paper
      className={`${Styles.BottomTabs} ${i18n.resolvedLanguage === 'ar' && Styles.BottomTabs_rtl}`}
      elevation={3}
    >
      <BottomNavigation showLabels value={location.pathname}>

        {
          bottomTabsPages.map(page => {
            return <BottomNavigationAction
              key={page.route}
              label={
                <Typography
                  sx={{ marginTop: '0px' }}
                  variant="body1"
                  fontSize={12}
                  component="p"
                  color={location.pathname === page.route ? theme.palette.primary.main : "#0008"}
                >
                  {t(page.label)}
                </Typography>
              }
              value={page.route}
              icon={
                !page.hasBadge ? <Box sx={{ display: 'inline-block' }}>
                  <page.icon
                    color={location.pathname === page.route ? theme.palette.primary.main : "#0008"}
                  />
                </Box> : <Badge variant="dot" invisible={cartCtx.items.length === 0} color="primary">
                  <page.icon
                    color={location.pathname === page.route ? theme.palette.primary.main : "#0008"}
                  />
                </Badge>
              }
              component={Link}
              to={page.route}
              style={(page.label === 'Orders' && infoCtx.info?.account_active === true) ? { display: 'flex' } : {}}
            />
          })
        }

      </BottomNavigation>
    </Paper>
      <Box sx={{ height: '30px' }} />
    </>
  );
};

export default BottomTabs;
