import Styles from '../ProductDetailsPage.module.css';
import { ProductImageSkeleton } from "../../../components/skeleton/ProductDetailsSkeleton/ProductDetailsSkeleton";
import config from '../../../config/config';
import { Box } from '@mui/material';

const ProductImage = ({isLoading,data}) => {
    return (
        <>
            {((!isLoading && !data?.options && data?.item?.image)) && <Box sx={{ height: '70px' }} />}
            {(!isLoading && data?.item?.image) && <img className={Styles.Product_image} style={{ height: '230px', width: '100%', objectFit: 'cover' }} src={data?.item?.image?.includes('demo') ? config.url + '/' + data?.item?.image : config.assets + '/' + data?.item?.image} alt="" />}
            {(!isLoading && data?.category_img && !data?.item?.image) && <img className={Styles.Product_image} style={{ height: '230px', width: '100%', objectFit: 'cover' }} src={data?.category_img?.includes('demo') ? config.url + '/' + data?.category_img : config.assets + '/' + data?.category_img} alt="" />}
            {isLoading && <ProductImageSkeleton />}
        </>
    )
};

export default ProductImage;