import { useTranslation } from "react-i18next";

const CartTrashIcon = (props) => {
    const { i18n } = useTranslation();
    return (
        <>
            {i18n.resolvedLanguage === 'en' && <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10C0 4.47715 4.47715 0 10 0H44V24.014C44 29.5368 39.5228 34.014 34 34.014H0V10Z" fill="#eee" />
                <path d="M24.12 14.47L22 16.59L19.87 14.47L18.46 15.88L20.59 18L18.47 20.12L19.88 21.53L22 19.41L24.12 21.53L25.53 20.12L23.41 18L25.53 15.88L24.12 14.47ZM25.5 8L24.5 7H19.5L18.5 8H15V10H29V8H25.5ZM16 23C16 24.1 16.9 25 18 25H26C27.1 25 28 24.1 28 23V11H16V23ZM18 13H26V23H18V13Z" fill="#172327" />
            </svg>}

            {i18n.resolvedLanguage === 'ar' && <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H34C39.5228 0 44 4.47715 44 10V34.014H10C4.47715 34.014 0 29.5368 0 24.014V0Z" fill="#eee" />
                <path d="M24.12 14.47L22 16.59L19.87 14.47L18.46 15.88L20.59 18L18.47 20.12L19.88 21.53L22 19.41L24.12 21.53L25.53 20.12L23.41 18L25.53 15.88L24.12 14.47ZM25.5 8L24.5 7H19.5L18.5 8H15V10H29V8H25.5ZM16 23C16 24.1 16.9 25 18 25H26C27.1 25 28 24.1 28 23V11H16V23ZM18 13H26V23H18V13Z" fill="#172327" />
            </svg> }

        </>

    );
};

export default CartTrashIcon;
