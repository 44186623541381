import axiosInstance from "../config/axios";

export const fetchCities = async (lang) => {
    try {
       const res = await axiosInstance.get(`/${lang}/branch-cities`)
        return res.data?.cities;
    
    } catch (error) {
        console.log(error);
    }

}


export const fetchBranches = async (lang) => {
    try {
        const branchesRes = await axiosInstance.get(`/${lang}/branches`);
        return branchesRes.data.branches;
    } catch (error) {
        
    }


}