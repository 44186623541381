import { useQuery } from "@tanstack/react-query";
import { fetchBranches, fetchCities } from "../providers/branchCities";
import { useTranslation } from "react-i18next";

export const useBranchCities = ()=>{
    const { i18n } = useTranslation();
    const branchCitiesQuery = useQuery({
        queryKey:['branch-cities'],
        queryFn:()=>fetchCities(i18n.resolvedLanguage)
    })
    return branchCitiesQuery;
};

export const useBranches = ()=>{
    const { i18n } = useTranslation();
    const branchesQuery = useQuery({
        queryKey:['branches'],
        queryFn:()=>fetchBranches(i18n.resolvedLanguage)
    })
    return branchesQuery;
};
