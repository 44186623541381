import { Button } from "@mui/material";
import Error from "../../components/Error/Error";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFoundPage = ()=>{
    const { t } = useTranslation();
    return <Error title="404" subtitle={t("Page not found") }>
        <Button variant="contained" color="primary" component={Link} to="/" style={{marginTop:'15px'}}>{t('Back To Home')}</Button>
    </Error>;
};

export default NotFoundPage;