
import { useContext, useEffect, useState } from 'react';
import { Box, Typography, Fab, List, ListItem, ListItemText, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomAppBar from '../../components/CustomAppBar/CustomAppBar';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import CustomerContext from '../../store/customer-context';
import InputDialog from './components/InputDialog';


const ProfilePage = () => {
    const { t } = useTranslation();
    const customerCtx = useContext(CustomerContext);
    const [isNameDialogOpen, setNameDialogOpen] = useState(false);
    const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
    const [isAddressDialogOpen, setAddressDialogOpen] = useState(false);
    const [isCityDialogOpen, setCityDialogOpen] = useState(false);
    const [userName, setUserName] = useState(customerCtx.info?.name??'');
    const [email, setEmail] = useState(customerCtx.info?.email??'');
    const [address, setAddress] = useState(customerCtx.info?.address??'');
    const [city, setCity] = useState(customerCtx.info?.city??'');

    const userNameChangeHandler = (value) => {
        setUserName(value);
    }

    const emailChangeHandler = (value) => {
        setEmail(value);
    };

    const addressChangeHandler = (value) => {
        setAddress(value);
    }

    const cityChangeHandler = (value) => {
        setCity(value);
    }



    return <Box className="ProfilePage" sx={{ height: '100%' }}>
        <CustomAppBar isFloating={true} notif={true} hasBack backDark />
        <UserAvatar edit />

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

            <InputDialog
                label={t("Full Name")}
                Updatekey="name"
                initialObject={customerCtx.info}
                value={userName}
                defaultValue={customerCtx.info?.name}
                isOpen={isNameDialogOpen}
                onOpen={() =>
                    setNameDialogOpen(true)}
                onClose={() => setNameDialogOpen(false)}
                onChange={userNameChangeHandler}
                onSubmit={() => { customerCtx.fetch(); setNameDialogOpen(false); }}
            />

            <InputDialog
                label={t("Email")}
                Updatekey="email"
                initialObject={customerCtx.info}
                value={email}
                defaultValue={customerCtx.info?.email}
                isOpen={isEmailDialogOpen}
                onOpen={() =>
                    setEmailDialogOpen(true)}
                onClose={() => setEmailDialogOpen(false)}
                onChange={emailChangeHandler}
                onSubmit={() => { customerCtx.fetch(); setEmailDialogOpen(false); }}
            />

            <InputDialog
                label={t("Address")}
                Updatekey="address"
                initialObject={customerCtx.info}
                value={address}
                defaultValue={customerCtx.info?.address}
                isOpen={isAddressDialogOpen}
                onOpen={() =>
                    setAddressDialogOpen(true)}
                onClose={() => setAddressDialogOpen(false)}
                onChange={addressChangeHandler}
                onSubmit={() => { customerCtx.fetch(); setAddressDialogOpen(false); }}
            />


            <InputDialog
                label={t("City")}
                Updatekey="city"
                initialObject={customerCtx.info}
                value={city}
                defaultValue={customerCtx.info?.city}
                isOpen={isCityDialogOpen}
                onOpen={() =>
                    setCityDialogOpen(true)}
                onClose={() => setCityDialogOpen(false)}
                onChange={cityChangeHandler}
                onSubmit={() => { customerCtx.fetch(); setCityDialogOpen(false); }}
            />
        </List>
    </Box>
};

export default ProfilePage;