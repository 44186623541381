import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import AuthContext from "../store/auth-context";
import { useQuery } from "@tanstack/react-query";
import { fetchCategories } from "../providers/categories";

const useCategories = () => {
    const { i18n } = useTranslation();
    const authCtx = useContext(AuthContext);
    const categoriesQuery = useQuery({
        queryKey: ['categories'],
        queryFn: () => fetchCategories(authCtx, i18n)
    })

    return categoriesQuery;

}

export default useCategories;