import { useQuery } from "@tanstack/react-query";
import { fetchBanner } from "../providers/banners";

const useBanners = ()=>{
    const bannersQuery = useQuery({
        queryKey:['banners'],
        queryFn:fetchBanner
    })
    return bannersQuery;
};

export default useBanners;