import { Card,  Box, Typography,ButtonBase,useTheme} from "@mui/material";
import { useTranslation } from "react-i18next";
import IncIcon from '../../../components/Icons/IncreaseIcon';
import DecreaseIcon from '../../../components/Icons/DecreaseIcon';
import { ProductDescriptionSkeleton, ProductTitleSkeleton } from "../../../components/skeleton/ProductDetailsSkeleton/ProductDetailsSkeleton";


const ProductHeader = ({isLoading,data,itemTotals,qty,onDecrement,onIncrement,enableQty}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();


    return (
        <Card elevation={0} sx={{ mx: '15px', my: '5px', mb: '0', padding: '15px', backgroundColor: '#fff', borderRadius: '15px 15px 0 0' }}>
            <Box sx={{ mt: '-10px', mb: (data?.options && data?.options.length > 1) ? '30px' : 0, paddingTop: '10px', position: 'relative', zIndex: 5 }}>
                {!isLoading && <Typography variant="h5" component="h5" sx={{ mb: '10px' }}>{data?.item?.name}</Typography>}
                {!isLoading && <Typography variant="body1" color="#172327" component="p" sx={{ mb: '16px' }}>{data?.item?.desc}</Typography>}
                {isLoading && <ProductTitleSkeleton />}
                {isLoading && <ProductDescriptionSkeleton />}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    {((itemTotals.sizeTotal + itemTotals.optionExtrasTotal + itemTotals.generalExtras) * qty) > 0 && <Typography variant='h6'>{(itemTotals.sizeTotal + itemTotals.optionExtrasTotal + itemTotals.generalExtras) * qty} {t('EGP')}</Typography>
                    }
                    {((itemTotals.sizeTotal + itemTotals.optionExtrasTotal + itemTotals.generalExtras) * qty) === 0 && <Typography variant='h6'>{t('Price on selection')}</Typography>}
                </Box>
                <Box>
                    {enableQty && <Box sx={{ display: 'flex', flexDirection: i18n.resolvedLanguage === 'en' ? 'row' : 'row-reverse', alignItems: 'center', mt: '10px' }}>
                        <ButtonBase onClick={onDecrement} disabled={qty === 1} ><DecreaseIcon color={theme.palette.primary.main} /></ButtonBase>
                        <Box sx={{ px: '10px' }}><Typography sx={{ textAlign: 'center' }}>{qty}</Typography></Box>
                        <ButtonBase onClick={onIncrement}><IncIcon color={theme.palette.primary.main} /></ButtonBase>
                    </Box>}
                </Box>
            </Box>
        </Card>
    );
};

export default ProductHeader;