import Styles from './DeliverySelector.module.css';
import { Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import DeliveryIcon from '../../../../assets/images/deliveryIcon.png';
import PickUpIcon from '../../../../assets/images/PickupIcon.svg';
import { useTranslation } from 'react-i18next';
import CheckoutContext from '../../../../store/checkout-context';
import { useLocation  } from "react-router-dom";

const DeliverySelector = (props)=>{
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { deliveryType } = useContext(CheckoutContext);
    const [ option ,setOption ] = useState(props.selected);
  

  

    const onOptionChange = (e)=>{
        let value = e.target.value;
        setOption(value);
        props.onchange(value);
    };

 
    return (
        <div className={`${Styles["delivery_option--selector"]} ${ option === 'pickup' && Styles["delivery_option--selected"]}`} style={{backgroundColor:option === 'pickup' ? theme.palette.primary.main : '#eee'}}>
        <div className={`${Styles["delivery_option"]} ${option === 'delivery' && Styles["delivery_option--selected"]}`} style={{backgroundColor:option === 'delivery' ? theme.palette.primary.main : '#eee'}}>
            <input type="radio" name="delivery_option" checked={option === 'delivery'} value="delivery" id="delivery" onChange={onOptionChange} />
            <label htmlFor="delivery" ><img src={DeliveryIcon} style={{width:'30px',marginRight:i18n.resolvedLanguage === 'ar'?'0':'16px',marginLeft:i18n.resolvedLanguage === 'ar'?'16px':'0'}} /><Typography component="span">{t('Delivery')}</Typography></label>
        </div>
        <div className={`${Styles["delivery_option"]} ${Styles["delivery_option--full"]}`}>
            <input type="radio" name="delivery_option" checked={option === 'pickup'} value="pickup" id="pickup" onChange={onOptionChange} />
            <label htmlFor="pickup"><img src={PickUpIcon} style={{width:'30px',marginRight:i18n.resolvedLanguage === 'ar'?'0':'16px',marginLeft:i18n.resolvedLanguage === 'ar'?'16px':'0'}} /><Typography component="span" >{t('Pick Up')}</Typography></label>
        </div>
    </div>
    );
};

export default DeliverySelector;