import { useNavigate } from 'react-router-dom';
import Styles from './welcome.module.css';
import { Button, Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config/config';
import { useContext } from 'react';
import { ThemeOptionsContext } from '../../theme/ThemeProvider';
import InfoContext from '../../store/info-context';
import LogoImg from '../../assets/images/logo.png';
import { getTranslation } from '../../utils/helper';

const WelcomePage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const appearanceCtx = useContext(ThemeOptionsContext);
    const infoCtx = useContext(InfoContext);
    const theme = useTheme();
 
    return (
        <Box id="WelcomePage" className={`${Styles.page_container} p-16 d-flex flex-column justify-content-center h-100`} style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: appearanceCtx.appearance.intro_bg_image ? `url(${config.assets}/${appearanceCtx.appearance.intro_bg_image})` : 'none', backgroundColor: appearanceCtx.appearance?.intro_bg_color ? appearanceCtx.appearance?.intro_bg_color : 'transparent' }}>
            {appearanceCtx.appearance.intro_bg_image && <span className={Styles.page_container_overlay}></span>}
            <div className="brand">
                <Box className="intro_brand__logo"  >

                    {infoCtx.info?.logo && <img src={config.assets + infoCtx.info?.logo} alt="" className={`${Styles['intro_brand__logo--img']}`} />}
                    {!infoCtx.info?.logo && <Box sx={{ backgroundColor: appearanceCtx.appearance.intro_text_color ? appearanceCtx.appearance.intro_text_color : '#000' }} className={`${Styles['intro__logo--placeholder']}`}> <Typography>{getTranslation(infoCtx.info, i18n.resolvedLanguage)?.name}</Typography></Box>}
                </Box>
                {!infoCtx.info?.welcome_msg && <>
                    <Typography className={Styles.visible_desktop} textAlign="center" fontWeight="bold" variant="h5" component="h5" sx={{ color: !appearanceCtx.appearance.intro_bg_image ? '#000' : appearanceCtx.appearance.intro_text_color }}>{t('Welcome to')} {getTranslation(infoCtx.info, i18n.resolvedLanguage)?.name}</Typography>
                    <Typography className={Styles.visible_mobile} textAlign="center" fontWeight="bold" variant="h5" component="h5" sx={{ color: '#Fff' }}>{t('Welcome to')} {getTranslation(infoCtx.info, i18n.resolvedLanguage)?.name}</Typography>
                </>
                }

                {infoCtx.info?.welcome_msg  && <>
                    <Typography className={Styles.visible_desktop} textAlign="center" fontWeight="bold" variant="h5" component="h5" sx={{ color: !appearanceCtx.appearance.intro_bg_image ? '#000' : appearanceCtx.appearance.intro_text_color }}>{infoCtx.info?.welcome_msg}</Typography>
                    <Typography className={Styles.visible_mobile} textAlign="center" fontWeight="bold" variant="h5" component="h5" sx={{ color: '#Fff' }}>{infoCtx.info?.welcome_msg}</Typography>
                </>
                }


            </div>
            <Box sx={{ mt: "50px" }} className={Styles.visible_mobile}>
                <Button color={appearanceCtx.appearance.intro_bg_image ? 'light' : 'primary'} disableElevation sx={{ width: '100%' }} size="large" variant="contained" onClick={() => navigate('/login')}>{t('Login')}</Button>

                <Box style={{ height: '20px' }}></Box>
                <Button color={appearanceCtx.appearance.intro_bg_image ? 'light' : 'primary'} disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={() => navigate('/products')}>{t('Continue without Account')}</Button>
            </Box>

            <Box sx={{ mt: "50px" }} className={Styles.visible_desktop}>
                <Button data-cy="login-button" color={'primary'} disableElevation sx={{ width: '100%' }} size="large" variant="contained" onClick={() => navigate('/login')}>{t('Login')}</Button>

                <Box style={{ height: '20px' }}></Box>
                <Button data-cy="guest-button" color={'primary'} disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={() => navigate('/products')}>{t('Continue without Account')}</Button>
            </Box>

        </Box>
    )
};

export default WelcomePage;