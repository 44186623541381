import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OrderTotals = ({totals})=>{
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box sx={{backgroundColor:'#fff',pt:'16px',mb:'16px'}}>
               <Box><Typography variant='h6' sx={{ marginBottom: '16px' }}>{t('Order Totals')}</Typography></Box>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography fontWeight="bold">{t('Subtotal')}</Typography></Box>
                <Box><Typography> {totals?.subtotal} {t('EGP')}</Typography></Box>
            </Box>
           
          {totals?.order_coupons[0]?.amount_spent &&  <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography color={theme.palette.primary.main} fontWeight="bold">{t('Coupon')}</Typography></Box>
                <Box><Typography color={theme.palette.primary.main}> -{totals?.order_coupons[0]?.amount_spent} {t('EGP')}</Typography></Box>
            </Box>}
            {totals?.discount &&  <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography fontWeight="bold">{t('Discount')}</Typography></Box>
                <Box><Typography> {totals?.discount} {t('EGP')}</Typography></Box>
            </Box>}
            {totals?.delivery_fees &&  <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography fontWeight="bold">{t('Delivery')}</Typography></Box>
                <Box><Typography> {totals?.delivery_fees} {t('EGP')}</Typography></Box>
            </Box>}
            

            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography fontWeight="bold">{t('Taxes')}</Typography></Box>
               { totals?.tax_type === 'exclusive' &&  <Box><Typography> {totals?.taxes} {t('EGP')}</Typography></Box>}
               { totals?.tax_type === 'inclusive' &&  <Box><Typography> {t('Included')}</Typography></Box>}
            </Box>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mb:'10px'}}>
                <Box><Typography fontWeight="bold">{t('Total')}</Typography></Box>
                <Box><Typography> {totals?.total} {t('EGP')}</Typography></Box>
            </Box>
        </Box>
    );
};

export default OrderTotals;