import axiosInstance from "../config/axios";

export const fetchOrders = async (auth,i18n) => {
    try {
      let ordersRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/customer/orders`);
      return  ordersRes.data.orders;
    } catch (error) {
      console.log('orders error', error);
    }
  };


  export const fetchOrder = async (id,auth,i18n) => {
    try {
        const orderRes = await axiosInstance.get(`/${i18n.resolvedLanguage}/customer/orders/${id}`);
        return  orderRes.data.order;
    } catch (error) {
        console.log('fetchOrder error', error);
    }
}