import { Typography, Toolbar, AppBar, IconButton, Box, useTheme, Alert,Button, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from '@mui/icons-material/ArrowForward'
import Styles from "./CustomAppBar.module.css";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import { Link, useNavigate } from "react-router-dom";
import CartIcon from "../Icons/CartIcon";
import { useContext } from "react";
import CartContext from "../../store/cart-context";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import CheckoutContext from "../../store/checkout-context";
import InfoContext from "../../store/info-context";
import ClosedBanner from "../ClosedBanner/ClosedBanner";

const CustomAppBar = (props) => {
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const authCtx = useContext(AuthContext);
    const checkoutCtx = useContext(CheckoutContext);
    const infoCtx = useContext(InfoContext);
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    return (
        <Box>



           { infoCtx.info?.account_active === true && <AppBar
                position={"fixed"}
                elevation={0}
                sx={{ backgroundColor: props.isFloating ? 'transparent' : '#fff' }}
                color={"transparent"}
                className={`${Styles.AppBar} ${i18n.resolvedLanguage === 'ar' && Styles.AppBar_rtl}`}
            >
                <ClosedBanner/>
                <Toolbar>
                    {props.hasMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {(props.hasBack && !props.backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !props.backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {

                                navigate(-1);

                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={"#000"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={"#000"} />}
                        </IconButton>
                    )}


                    {(props.hasBack && props.backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !props.backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {
                                navigate(props.backRoute);
                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={"#000"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={"#000"} />}
                        </IconButton>
                    )}

                    {!props.gpsTop && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {props.title && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {props.title}
                        </Typography>
                        }


                    </Box>}
                    {(props.gpsTop && props.enableGps) && <Box
                        sx={{ overflow: 'hidden', textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                            if (!props.disableGpsLink) {
                                navigate('/delivery');
                            }
                        }}>
                        {checkoutCtx.deliveryType === 'delivery' && <Typography
                            variant="h6"
                            component="div"
                            color={theme.palette.primary.main}
                            fontSize="12px"
                            fontWeight="bold"
                            sx={{ width: '60%' }}
                        >
                            {t('Delivery to')}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'pickup' && <Typography
                            variant="h6"
                            component="div"
                            color={theme.palette.primary.main}
                            fontSize="12px"
                            fontWeight="bold"
                            sx={{ width: '60%' }}
                            title={checkoutCtx.deliverAddress?.address}
                        >
                            {t('Pick Up')}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'delivery' && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"
                            sx={{ width: '60%', mx: 'auto' }}
                            noWrap
                        >
                            {checkoutCtx.deliverAddress?.address}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'pickup' && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {authCtx.branch?.name}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'dinein' && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {authCtx.branch?.name}, Section  {checkoutCtx.dineInOption.section}, Table  {checkoutCtx.dineInOption.table}
                        </Typography>}
                        {!checkoutCtx.deliveryType && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                       { infoCtx.info?.delivery_pickup === 'all' &&   <>
                          {t('Select your Location for delivery')} <br />
                          {t('or Branch for Pickup')}
                          </> }
                          { infoCtx.info?.delivery_pickup === 'delivery' &&   <>
                            {t('Select your Location for delivery')}
                          </> }
                          { infoCtx.info?.delivery_pickup === 'pickup' &&   <>
                            {t('Select your Branch for Pickup')}
                          </> }
                        </Typography>}
                    </Box>}

                    {props.notif && <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ ml: "auto" }}
                        component={Link}
                        to="/notifications"
                    >
                        <NotificationsOutlined htmlColor="#426668" />
                    </IconButton>}

                    {props.cart && <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="cart"
                        sx={{ ml: "auto", backgroundColor: !props.backDark ? '#fff8' : 'transparent',p:'15px' }}
                        onClick={() => {
                            navigate('/cart');
                        }}
                    >
                        {cartCtx.items.length > 0 && <span className={Styles.cartBadge}></span>}
                        <CartIcon color="#000" />
                    </IconButton>}
                </Toolbar>
                {(!props.gpsTop && props.enableGps && checkoutCtx.deliveryType ) && <Toolbar>

                    <Box sx={{ textAlign: 'center', width: '100%' }} onClick={() => {
                        if (!props.disableGpsLink) {
                            navigate('/delivery');
                        };
                    }}>
                        {checkoutCtx.deliveryType === 'delivery' && <> <Typography
                            variant="h6"
                            component="div"
                            color={theme.palette.primary.main}
                            fontSize="12px"
                            fontWeight="normal"
                            sx={{ flexGrow: 1 }}
                        >
                            {t('Delivery to')}
                        </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                color="#426668"
                                fontSize="14px"
                                fontWeight="normal"
                                sx={{ width: '60%', mx: 'auto' }}
                                noWrap
                                title={checkoutCtx.deliverAddress?.address}
                            >
                                {checkoutCtx.deliverAddress?.address}
                            </Typography>
                        </>}
                        {checkoutCtx.deliveryType === 'pickup' && <> <Typography
                            variant="h6"
                            component="div"
                            color={theme.palette.primary.main}
                            fontSize="12px"
                            fontWeight="normal"
                            sx={{ flexGrow: 1 }}
                        >
                            {t('Pick Up')}
                        </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                color="#426668"
                                fontSize="14px"
                                fontWeight="normal"
                                sx={{ width: '60%', mx: 'auto' }}
                                noWrap
                            >
                                {authCtx.branch?.name}
                            </Typography>
                        </>}
                        {checkoutCtx.deliveryType === 'dinein' && <> <Typography
                            variant="h6"
                            component="div"
                            color={theme.palette.primary.main}
                            fontSize="12px"
                            fontWeight="normal"
                            sx={{ flexGrow: 1 }}
                        >
                            {t('Dine in')}
                        </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                color="#426668"
                                fontSize="14px"
                                fontWeight="normal"
                                sx={{ width: '60%', mx: 'auto' }}
                                noWrap
                            >
                                {authCtx.branch?.name},  Section {checkoutCtx.dineInOption.section}, Table { checkoutCtx.dineInOption.table }
                            </Typography>
                        </>}
                        {!checkoutCtx.deliveryType && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {t('Select your Location for delivery')} <br />
                            {t('or Branch for Pickup')}
                        </Typography>}
                    </Box>
                </Toolbar>}
                {(!props.gpsTop && props.enableGps && !checkoutCtx.deliveryType ) && <Alert sx={{cursor:'pointer'}}  action={
    <Button color="inherit" size="small">
      {t('Change')}
    </Button>
  } severity="warning" onClick={() => {
                        if (!props.disableGpsLink) {
                            navigate('/delivery');
                        };
                    }}>
                {t('Select your Location for delivery')} {t('or Branch for Pickup')}
</Alert>}
            </AppBar>}

            { infoCtx.info?.account_active === false && <AppBar
                position={"fixed"}
                elevation={0}
                sx={{ backgroundColor: props.isFloating ? 'transparent' : '#fff' }}
                color={"transparent"}
                className={`${Styles.AppBar} ${i18n.resolvedLanguage === 'ar' && Styles.AppBar_rtl}`}
            >
                <Toolbar>
                    {props.hasMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {(props.hasBack && !props.backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !props.backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {

                                navigate(-1);

                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={"#000"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={"#000"} />}
                        </IconButton>
                    )}


                    {(props.hasBack && props.backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !props.backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {
                                navigate(props.backRoute);
                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={"#000"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={"#000"} />}
                        </IconButton>
                    )}

                    {!props.gpsTop && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {props.title && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {props.title}
                        </Typography>
                        }
                        


                    </Box>}
               
                    {(props.gpsTop && props.title && infoCtx.info?.account_active === false) && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {props.title && <Typography
                            variant="h6"
                            component="div"
                            color="#426668"
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {props.title}
                        </Typography>
                        }
                        


                    </Box>}
              

                    {props.cart && <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="cart"
                        sx={{ ml: "auto", backgroundColor: !props.backDark ? '#fff8' : 'transparent',p:'15px' }}
                        onClick={() => {
                            navigate('/cart');
                        }}
                    >
                        {cartCtx.items.length > 0 && <span className={Styles.cartBadge}></span>}
                        <CartIcon color="#000" />
                    </IconButton>}
                </Toolbar>
       
  

            </AppBar>}
            { infoCtx.closed && <Box sx={{ height: '60px' }}></Box> }
            {!props.isFloating && <Box sx={{ height: (props.enableGps && !props.gpsTop) ? '120px' : '60px' }}></Box>}
        </Box>
    );
};

export default CustomAppBar;
