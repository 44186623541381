import './style.css';

const BannerSkeleton = () => {

    const skeletonData = Array.from(Array(1).keys());


    return <div className='banner-skeleton'>
        <div className='banner-skeleton__wrapper' >
        { skeletonData.map((item, i) => {
                return (<div key={i} className='banner-skeleton__item skeleton'></div>)
            })
        }
    </div>
    </div>
}



export default BannerSkeleton;