import './style.css';
import { Grid, Paper } from '@mui/material';

const OrdersSkeleton = ()=>{
    const skeletonData = Array.from(Array(4).keys());


    return <div className='orders-skeleton'>
        <div className='orders-skeleton__wrapper' >
        { skeletonData.map((item, i) => {
                return (<Paper elevation={1} key={i} className='orders-skeleton__item'>
                    <div className='text-skeleton skeleton'></div>
                    <div className='text-skeleton skeleton'></div>
                    <div className='text-skeleton skeleton'></div>
                    <Grid container columnSpacing={1} sx={{marginTop:'16px'}}>
                        <Grid item xs={6}>
                            <div className='btn-skeleton skeleton'></div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className='btn-skeleton skeleton'></div>
                        </Grid>
                    </Grid>
                </Paper>)
            })
        }
    </div>
    </div>
};

export default OrdersSkeleton;