import { Box, Button, Typography } from "@mui/material";
import CartEmptyImage from "../../../../assets/images/cart_empty.png";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
 
const CartEmpty = () => {
const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
            <img src={CartEmptyImage} alt="" style={{ width: '60%', aspectRatio: 1, objectFit: 'contain', marginBottom: '30px' }} />
            <Typography textAlign="center" fontWeight="bold" color="#172327" variant="h5" component="h5">{t('Your Cart is Empty')}</Typography>
            <Typography textAlign="center" color="#989898" variant="body1" component="p" sx={{ mb: '30px' }}>{t("Looks like you haven’t added anything to your cart yet")}</Typography>
            <Button variant="contained" size="large" component={Link} to="/products">{t('Go to Home')}</Button>
        </Box>
    )
};

export default CartEmpty;