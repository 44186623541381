import axiosInstance from "../config/axios";
import i18n from "../config/i18n";

export const fetchBanner = async () => {

    try {
      const bannerRes = await axiosInstance.get(`${i18n.resolvedLanguage}/banners`);
      return bannerRes.data;
    } catch (error) {
      
    }
  }